<input
    [type]="type"
    [min]="min"
    [max]="max"
    [step]="step"
    [disabled]="isDisabled"
    [required]="isRequired"
    [(ngModel)]="val"
    (ngModelChange)="onChange($event)"
    [id]="innerId"
    [class]="aggregatedClass"
    [ngClass]="{disabled: isDisabled}"
    [name]="name"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete || 'off'"
    [style]="style"
    (blur)="onBlur()"
    [attr.data-st-theme]="theme"
/>

<span role="alert" class="text-st-danger" *ngIf="isRequired && !val">
    <small><strong>Ce champ est obligatoire.</strong></small>
</span>

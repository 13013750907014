<div
    class="form-check mb-0 {{ containerClass }}"
    [ngClass]="{'checkbox-transparent': isTransparent, 'text-st-muted': isDisabled}"
>
    <input
        type="checkbox"
        [id]="innerId"
        class="form-check-input"
        [ngClass]="inputClass"
        [disabled]="isDisabled"
        [(ngModel)]="value"
        [checked]="val"
    />
    <label
        [for]="innerId"
        class="form-check-label {{ labelClass }}"
        [ngClass]="{'cursor-not-allowed': isDisabled, 'selected': val}"
    >
        <ng-content></ng-content>
    </label>
</div>

<ng-template #connectionCostTemp>
    <ng-container
        *ngIf="!connectionCost.isLoading && (connectionCost.isAvailable && connectionCost.isActive && solarService.connectionCostInfo.isEnedisAvailable)"
    >
        <stButton
            btnLabel="Estimez le coût de raccordement grâce à Enedis."
            btnClass="text-st-link text-start p-0"
            (onClick)="getConnectionCost()"
        >
        </stButton>
    </ng-container>

    <ng-container *ngIf="connectionCost.isLoading">
        <div class="d-flex align-items-center">
            <div class="text-st-primary">Connexion à l'API Raccordement d'Enedis en cours...</div>
            <span class="fa fa-spinner fa-2x fa-pulse text-st-active ms-3"></span>
        </div>
    </ng-container>
</ng-template>

<div class="container-fluid solar-card {{ customClass }}">
    <div class="row d-flex align-items-center mb-2">
        <div
            class="col-1 d-flex align-items-center justify-content-center text-st-primary icon-container"
        >
            <ng-container *ngIf="icon">
                <i [class]="icon"></i>
            </ng-container>
            <ng-container *ngIf="iconSvg">
                <st-svg [svgName]="iconSvg" [size]="24"></st-svg>
            </ng-container>
        </div>

        <div class="col text-st-primary ps-0 fs-16">{{ titleLabel }}</div>

        <div class="col-auto px-0" *ngIf="modalContentHtml">
            <stButton
                class="btn-info-modal"
                btnClass="d-flex align-items-center py-0"
                iconLeft="bi bi-info-circle-fill text-st-secondary fs-5"
                iconLeftHover="bi bi-info-circle fs-5"
                (onClick)="openInfoModal(titleLabel, modalContentHtml)"
                title="Plus d'information"
            ></stButton>
        </div>
    </div>

    <div [innerHtml]="contentHtml"></div>

    <ng-container *ngIf="!!connectionCost">
        <ng-container *ngTemplateOutlet="connectionCostTemp"></ng-container>
    </ng-container>
</div>

import { Component, Inject, OnInit, PipeTransform, QueryList, ViewChildren } from '@angular/core';

import { Scenario } from 'src/app/models/Scenarios';
import { User } from 'src/app/models/User';
import { ReversePipe } from 'ngx-pipes';

import { DataService } from 'src/app/services/DataService';
import { ScenariosService } from 'src/app/services/ScenariosService';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'src/app/controllers/main/modals/confirmation/confirmation-modal.component';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/components/st-table/sortable.directive';
import { StTableService } from 'src/app/components/st-table/st-table.service';

function matches(scenario: any, term: string, pipe: PipeTransform) {
    const lowerCaseTerm = term.toLowerCase();
    return (
        pipe.transform(scenario.id).includes(term) ||
        (scenario.name && scenario.name.toLowerCase().includes(lowerCaseTerm)) ||
        (scenario.creatorUserName &&
            scenario.creatorUserName.toLowerCase().includes(lowerCaseTerm)) ||
        (scenario.dateCreated && scenario.dateCreated.toLowerCase().includes(lowerCaseTerm)) ||
        (scenario.lastModifierUserName &&
            scenario.lastModifierUserName.toLowerCase().includes(lowerCaseTerm)) ||
        (scenario.dateLastModified &&
            scenario.dateLastModified.toLowerCase().includes(lowerCaseTerm))
    );
}
@Component({
    selector: 'scenariosModal',
    templateUrl: './scenarios-modal.template.html',
    styleUrls: [
        '../../../../components/st-table/st-table.component.scss',
        './scenarios-modal.service.scss',
    ],
    providers: [ReversePipe, StTableService],
})
export class ScenariosModalComponent implements OnInit {
    scenarios$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    isOpen = false;
    currentScenario: Scenario;
    waiter = {
        save: {
            progress: false,
            error: false,
            ok: false,
        },
        create: {
            progress: false,
            error: false,
            ok: false,
        },
    };
    rights: any;
    selectedRight: any;
    public scenarios: any;
    activeScenario: Scenario;
    isScenarioLoading: boolean;
    user: User;
    viewMode = 'list';

    scenarioToDelete: Scenario;

    constructor(
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private notification: ToastrService,
        public stTableService: StTableService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(DataService) private dataService: DataService,
        @Inject(ScenariosService) private scenariosService: ScenariosService,
    ) {
        this.scenarios$ = this.stTableService.rows$;
        this.total$ = this.stTableService.total$;
    }

    async ngOnInit(): Promise<void> {
        this.stTableService.matches = matches;
        this.isOpen = true;
        this.user = this.localStorageService.get('user');

        this.rights = this.scenariosService.rights;
        this.selectedRight = this.rights[0];

        const currentScenario = this.scenariosService.currentScenario;
        const isLoading = currentScenario.isLoading;
        const isLoaded = currentScenario.isLoaded;

        if (isLoaded) {
            this.currentScenario = currentScenario;
        } else {
            this._initCreate();
            this.currentScenario.isLoading = isLoading;
            this.currentScenario.name = currentScenario.name;
            this.scenariosService.setCurrentScenario(this.currentScenario);
        }
        try {
            const scenarios = await this.scenariosService.getScenarios();
            this._setActiveScenario(scenarios);
            this.scenarios = scenarios;
            this._setDatesScenarios();
            this.stTableService.setRows(this.scenarios);
        } catch (error) {
            console.error('Error initialization', error);
        }
    }

    onClose() {
        this.isOpen = false;
        this.stTableService.searchTerm = '';
    }

    private _initCreate() {
        this.currentScenario = {
            id: -1,
            name: '',
            rightId: 1,
            creatorUserId: null,
            isLoading: false,
            isLoaded: false,
            raster: null,
            territory: null,
            filters: null,
            indicators: null,
            plugins: null,
        };
    }

    updateRight(right) {
        this.selectedRight = right;
    }

    private _setActiveScenario(scenarios: Scenario[]) {
        this.scenarios = scenarios;

        this.activeScenario = null;

        if (!!this.dataService.activeScenario) {
            const scenario = this.scenarios.find(
                (scenario: Scenario) => scenario.id == this.dataService.activeScenario.id,
            );
            if (scenario) {
                this.activeScenario = { ...scenario };
                this.dataService.activeScenario = { ...scenario };
            }
        }
        if (!this.activeScenario) {
            this.dataService.activeScenario = null;
        }
    }

    create() {
        this._initCreate();
        this.viewMode = 'create';
    }

    async validateCreate() {
        this.waiter.create.progress = true;

        this.viewMode = 'list';

        const data = this.scenariosService.setDataToSave();

        this.currentScenario.name = this.currentScenario.name || 'Mon étude';
        this.currentScenario.rightId = this.selectedRight.id;
        this.currentScenario.creatorUserId = this.user.id;
        this.currentScenario.lastModifierUserId = this.user.id;

        for (let key in data) {
            this.currentScenario[key] = data[key];
        }

        try {
            const scenarios = await this.scenariosService.create(this.currentScenario);
            this._setActiveScenario(scenarios);
            this.stopWaiters('create', 'ok');
            this.currentScenario = this.scenariosService.currentScenario;
        } catch (error) {
            console.error('Error validateCreate', error);
            this.stopWaiters('create', 'err');
        }
    }

    backToMain() {
        this._initCreate();
        this.viewMode = 'list';
    }

    async validateSave() {
        this.waiter.save.progress = true;
        this.currentScenario = this.scenariosService.currentScenario;

        const data = this.scenariosService.setDataToSave();
        for (let key in data) {
            this.currentScenario[key] = data[key];
        }

        try {
            const scenarios = await this.scenariosService.replace(
                this.currentScenario.id,
                this.currentScenario,
            );
            this._setActiveScenario(scenarios);
            this.stopWaiters('save', 'ok');
        } catch (error) {
            console.error('Error validateSave', error);
            this.stopWaiters('save', 'err');
        }
    }

    update(scenario) {
        this.currentScenario = scenario;
        this.selectedRight = this.rights.find((right: any) => right.id == scenario.rightId);

        this.viewMode = 'update';
    }

    async validateUpdate() {
        this.currentScenario.rightId = this.selectedRight.id;
        this.viewMode = 'list';

        try {
            const scenarios = await this.scenariosService.partialUpdate(
                this.currentScenario.id,
                this.currentScenario,
            );
            if (this.scenariosService.currentScenario.id == this.currentScenario.id) {
                this.currentScenario = this.scenariosService.currentScenario;
            }
            this._setActiveScenario(scenarios);
        } catch (error) {
            console.error('Error validateUpdate', error);
        }
    }

    async getScenario(scenario: Scenario) {
        this.currentScenario = scenario;
        this.currentScenario.isLoading = true;

        try {
            await this.scenariosService.getScenario(scenario);
            this.currentScenario = this.scenariosService.currentScenario;
            this.modalService.dismissAll();
        } catch (error) {
            console.error('Error getScenario', error);

            this._initCreate();
            this.scenariosService.currentScenario = this.currentScenario;
            this.isScenarioLoading = false;

            this.notification.error(
                "Une erreur est survenue. Impossible charger l'enregistrement.",
            );
        }
    }

    stopWaiters = (id: string, state: string) => {
        this.waiter[id].progress = false;
        this.waiter[id][state] = true;

        let time: number;
        if (state === 'ok') {
            time = 0;
        } else if (state === 'save') {
            time = 600;
        } else {
            time = 1300;
        }
        setTimeout(() => {
            this.waiter[id][state] = false;
            this.modalService.dismissAll();
        }, time);
    };

    delete(scenario: Scenario) {
        this.scenarioToDelete = scenario;
        const header = `Suppresion d'enregistrement`;
        const body = `
            <p>
                Êtes-vous sûr de vouloir supprimer l\'enregistrement &laquo;
                <span class="text-st-active">${this.scenarioToDelete.name}</span>
                &raquo; créée par 
                <span class="text-st-primary">${this.scenarioToDelete.creatorUserName}</span>
                ?
            </p>
            <div>
                Toutes informations associées à cet enregistrement seront définitivement supprimées.
                <span class="text-st-danger">Cette action est irréversible.</span>
            </div>
            `;

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.header = header;
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            (result) => {
                this.confirmDelete();
            },
            () => {},
        );
        modalRef.componentInstance.name = 'confirmationModal';
    }

    async confirmDelete() {
        try {
            const scenarios = await this.scenariosService.delete(this.scenarioToDelete.id);
            this.currentScenario = this.scenariosService.currentScenario;
            this._setActiveScenario(scenarios);
            this._setDatesScenarios();
            this.stTableService.setRows(this.scenarios);
        } catch (error) {
            console.error('Error delete', error);
        }
    }

    onSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.stTableService.sortColumn = column;
        this.stTableService.sortDirection = direction;
    }

    private _setDatesScenarios() {
        this.scenarios.forEach((scenario: Scenario) => {
            const creationDate = new Date(scenario.dateCreated);
            const lastModificationDate = new Date(scenario.dateLastModified);

            scenario.dateCreated = creationDate.toLocaleDateString();
            scenario.dateCreatedTime = creationDate.getTime();

            scenario.dateLastModified = lastModificationDate.toLocaleDateString();
            scenario.dateLastModifiedTime = lastModificationDate.getTime();
        });
    }

    isScenarioAvailable(user: User, scenario: Scenario) {
        const isAdmin = user.role === 1;
        const isUserCreator = scenario.creatorUserId == user.id;
        const isScenarioAvailableForReadAndWrite = scenario.rightId == 3;

        return isAdmin || isUserCreator || isScenarioAvailableForReadAndWrite;
    }
}

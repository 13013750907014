import { Inject, Injectable } from '@angular/core';
import * as L from 'leaflet';

import {
    GeoLabelsParamsRest,
    TerritoryScale,
    Territory,
    TerritoryLabel,
    TerritoryLabelByYear,
    TerritorryByLocationRest,
} from '../models/TerritoryTypes';

import { EventService } from './event.service';
import { LoaderService } from 'src/app/services/LoaderService';
import { MapService } from './map.service';
import { RestService } from './RestService';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class TerService {
    private territoryLabelByYear: TerritoryLabelByYear = {};

    public territoryLayers: L.FeatureGroup;
    public territoryLabeledLayers: L.FeatureGroup;
    public territoryScaleLayers: any = null;
    public territoryScaleLabeledLayers: any = null;

    public territoryScales: TerritoryScale[];
    public geoYears: Array<number> = [2020];

    public geoYear: number = 2020;
    public territoryScale: TerritoryScale;
    public territories: Territory[];
    public granularity: TerritoryScale;

    constructor(
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(MapService) private mapService: MapService,
        @Inject(RestService) private restService: RestService,
    ) {
        this.clear();
    }

    clear() {
        this.territoryLabelByYear = {};
        this.geoYear = Math.max(...this.geoYears);
        this.territoryScale = null;
        this.territories = [];
        this.granularity = null;
    }

    async initTerritory(isAddressDefined: boolean = false, drawTerritory: boolean = true) {
        try {
            const preferences = this.localStorageService.get('preferences');
            const year = preferences.territory.year;

            await this.loadGeoYears();
            this.geoYear = year ? year : Math.max(...this.geoYears);

            await this.loadTerritoryScales();
            if (this.mapService.map) {
                this.initTerritoryLayers();
            }

            this.updateGeoYearFromUserPreference();
            this.updateTerritoryScaleFromUserPreference();
            this.updateGranularityFromUserPreference();
            if (isAddressDefined) {
                await this.updateTerritoriesFromAddress();
            } else {
                await this.updateTerritoriesFromUserPreference();
            }

            if (drawTerritory) {
                const preferences = this.localStorageService.get('preferences');
                const territoryPreference = preferences.territory;

                if (territoryPreference.drawTerritory) {
                    const territoryScale = this.territoryScales.find(
                        (t: TerritoryScale) => t.typeId == territoryPreference.scaleTypeId,
                    );
                    // no need to wait until geojson is loaded to continue
                    this.addTerritoryLayer(territoryScale);
                }
            }
        } catch (error) {
            console.error('Error initialisation', error);
            throw error;
        }
    }

    async loadGeoYears() {
        try {
            this.geoYears = await this.restService.getGeoYears();
            return;
        } catch (error) {
            console.error('Error loadGeoYears', error);
            throw error;
        }
    }

    async loadTerritoryScales(year: number = undefined, save: boolean = true) {
        const parameters = {
            year: year || this.geoYear,
        };
        try {
            const territoryScales = await this.restService.getGeoTerritoryScales(parameters);
            this.setFrontAttributes(territoryScales);
            territoryScales.sort((a: TerritoryScale, b: TerritoryScale) => b.order - a.order);

            if (save) {
                this.territoryScales = territoryScales;
            }
            return territoryScales;
        } catch (error) {
            console.error('Error loadTerritoryScales', error);
            throw error;
        }
    }

    setFrontAttributes(territoryScales: TerritoryScale[]) {
        territoryScales.forEach((territoryScale: TerritoryScale) => {
            territoryScale.type = territoryScale.id_terr;
            territoryScale.typeId = territoryScale.id_terr_int;
            territoryScale.labelLong = territoryScale.libelle_long;
            territoryScale.labelShort = territoryScale.libelle_short;
            territoryScale.order = territoryScale.ordre;
            territoryScale.isLabeled = false;
            territoryScale.isLoading = false;
            territoryScale.isLabelLoading = false;
            territoryScale.isLabelPloted = false;
            territoryScale.isPloted = false;
        });
    }

    saveTerritoryScales(territoryScales: TerritoryScale[]) {
        this.territoryScales = territoryScales;
    }

    updateGeoYear(geoYear: number) {
        this.geoYear = geoYear;
    }

    updateTerritoryScale(territoryScale: TerritoryScale) {
        this.territoryScales.forEach((t: TerritoryScale) => (t.isPloted = false));
        this.territoryScale = this.territoryScales.find((t) => t.id == territoryScale.id);
        this.territoryScale.isPloted = true;
    }

    updateTerritories(territories: Territory[]) {
        this.territories = territories;
    }

    updateGranularity(granularity: TerritoryScale) {
        this.granularity = granularity;
    }

    updateGeoYearFromUserPreference() {
        const preferences = this.localStorageService.get('preferences');
        const territoryPreference = preferences.territory;

        const geoYear = territoryPreference.year || this.geoYear;
        this.updateGeoYear(geoYear);
    }

    updateTerritoryScaleFromUserPreference() {
        const preferences = this.localStorageService.get('preferences');
        const territoryPreference = preferences.territory;

        const territoryScale = this.territoryScales.find(
            (t: TerritoryScale) => t.typeId == territoryPreference.scaleTypeId,
        );
        this.updateTerritoryScale(territoryScale);
    }

    updateGranularityFromUserPreference() {
        const preferences = this.localStorageService.get('preferences');
        const territoryPreference = preferences.territory;

        let granularity = this.territoryScales.find(
            (t: TerritoryScale) => t.typeId == territoryPreference.granularityTypeId,
        );
        if (!granularity) {
            const territoryScale = this.territoryScales.find(
                (t: TerritoryScale) => t.typeId == territoryPreference.scaleTypeId,
            );

            granularity = this.territoryScales.find(
                (t: TerritoryScale) => t.ordre == territoryScale.ordre - 1,
            );
        }
        this.updateGranularity(granularity);
    }

    async updateTerritoriesFromUserPreference() {
        const preferences = this.localStorageService.get('preferences');
        const territoryPreference = preferences.territory;
        const territoryIds = territoryPreference.territoryIds;
        const territories = territoryIds.map((id: string) => ({ id: id, label: '' }));
        this.updateTerritories(territories);
        await this.updateTerritoryLabels();
    }

    async updateTerritoriesFromAddress() {
        const feature = this.localStorageService.get('address');
        const cityCode = feature.properties.citycode;
        const longitude = (feature.geometry as GeoJSON.Point).coordinates[0];
        const latitude = (feature.geometry as GeoJSON.Point).coordinates[1];

        const newTerritory = await this.getTerritory(cityCode, longitude, latitude);
        const isAlreadyDefined = this.territories.some(
            (territory) => territory.id == newTerritory.id,
        );
        if (!isAlreadyDefined) {
            await this.replaceTerritories([newTerritory]);
        }
    }

    async replaceTerritories(newTerritories: Territory[]) {
        this.updateTerritories(newTerritories);
        await this.updateTerritoryLayer();
        this.eventService.emit('searchAddressSelected');
    }

    async getTerritory(cityCode: string, longitude: number, latitude: number) {
        try {
            const parameters = {
                cityCode: cityCode,
                scaleTypeId: this.territoryScale.typeId,
                year: this.geoYear,
                longitude: longitude,
                latitude: latitude,
            };

            const response: any = await this.restService.searchTerritory(parameters);
            return response;
        } catch (error) {
            console.error('Error getTerritory', error);
            throw error;
        }
    }

    async updateTerritoryLabels() {
        try {
            const parameters = {
                year: this.territoryScale.year,
                scaleTypeId: this.territoryScale.typeId,
                territoryIds: JSON.stringify(this.territories.map((t) => t.id)),
            };
            const labels = await this.restService.getGeoTerritoryLabels(parameters);
            this.territories.forEach(
                (t, i) => (t.label = labels[i] ? labels[i] : 'Territoire personnalisé'),
            );
            return;
        } catch (error) {
            console.error('Error updateTerritoryLabels', error);
            throw error;
        }
    }

    async getTerritoryLabelByYear(
        year: number,
        scaleType: string,
        scaleTypeLimit?: string,
        territoryIds: Array<string> = [],
    ) {
        try {
            let territoryTypeYear: string = `${scaleType}_${year}`;

            if (scaleType === 'ter_01' && !scaleTypeLimit) {
                scaleTypeLimit = this.territoryScale.type;
                territoryIds = this.territories.map((t) => t.id);
            }
            if (scaleTypeLimit && territoryIds.length) {
                territoryTypeYear += `_${scaleTypeLimit}_${territoryIds.join(',')}`;
            }

            const isAlreadyDefined = this.territoryLabelByYear[territoryTypeYear];
            const isBelowIris = territoryTypeYear.substring(0, 5) != 'ter_0';

            if (isAlreadyDefined && isBelowIris) {
                return this.territoryLabelByYear[territoryTypeYear];
            }

            const parameters: GeoLabelsParamsRest = {
                year: year,
                scaleTypeId: parseInt(scaleType.split('_')[1]),
            };
            if (scaleTypeLimit && territoryIds.length) {
                parameters.scaleTypeIdLimit = parseInt(scaleTypeLimit.split('_')[1]);
                parameters.territoryIds = JSON.stringify(territoryIds);
            }

            const labels = await this.restService.getGeoLabelsByScale(parameters);

            this.territoryLabelByYear[territoryTypeYear] = labels.map((l: TerritoryLabel) => ({
                ...l,
                labelId: `${l.label} (${l.id})`,
            }));
            return this.territoryLabelByYear[territoryTypeYear];
        } catch (error) {
            console.error('Error getTerritoryLabelByYear', error);
            throw error;
        }
    }

    initTerritoryLayers() {
        this.territoryScaleLayers = {};
        this.territoryScales.forEach((t: TerritoryScale) => {
            const layers = L.featureGroup().addTo(this.mapService.map);
            this.territoryScaleLayers[t.typeId] = layers;
        });

        this.territoryScaleLabeledLayers = {};
        this.territoryScales.forEach((t: TerritoryScale) => {
            const layers = L.featureGroup().addTo(this.mapService.map);
            this.territoryScaleLabeledLayers[t.typeId] = layers;
        });
    }

    async toggleTerritoryLayer(territoryScale: TerritoryScale) {
        let isAlreadyPloted = false;
        let layer: L.Layer;
        this.territoryLayers.eachLayer((lay: any) => {
            if (lay.territoryScale.id == territoryScale.id) {
                isAlreadyPloted = true;
                layer = lay;
            }
        });
        if (isAlreadyPloted) {
            this.removeTerritoryScaleLayers(territoryScale);
            return;
        }
        await this.addTerritoryLayer(territoryScale);
    }

    getLayersByTerritoryScaleType(territoryScale: TerritoryScale, labeled: boolean = false) {
        const territoryScaleTypeId = territoryScale.typeId;

        if (labeled) {
            return this.territoryScaleLabeledLayers[territoryScaleTypeId];
        }
        return this.territoryScaleLayers[territoryScaleTypeId];
    }

    removeTerritoryScaleLayers = (territoryScale: TerritoryScale) => {
        const featureGroup = this.getLayersByTerritoryScaleType(territoryScale);

        territoryScale.isPloted = false;
        featureGroup.clearLayers();
    };

    async addTerritoryLayer(territoryScale: TerritoryScale) {
        try {
            this.loaderService.start('drawTerritory');

            const data = {
                perimeterScaleTypeId: this.territoryScale.typeId,
                perimeterTerritoryIds: this.territories.map((t) => t.id),
                granularityScaleTypeId: territoryScale.typeId,
                year: this.geoYear,
                shape: 'line',
                isSimplified: territoryScale.type == 'ter_99',
            };
            const geojson = await this.restService.getGeojson(data);

            this.setLayer(territoryScale, geojson);
            this.loaderService.stop('drawTerritory');
        } catch (error) {
            console.error('Error addTerritoryLayer', error);
            this.loaderService.stop('drawTerritory');
            throw new Error(error);
        }
    }

    async updateTerritoryLayer() {
        this.clearTerritoryLayers();
        const territoryScale = this.territoryScales.find(
            (territoryScale: TerritoryScale) =>
                territoryScale.type == this.territoryScale.type && !!territoryScale.geom_line,
        );
        await this.addTerritoryLayer(territoryScale);
    }

    setLayer(territoryScale: any, geojson: any) {
        const style = territoryScale.style;
        style.weight = 2;

        const layer = L.geoJson(geojson, {
            style: style,
        });

        territoryScale.isPloted = true;
        this.territoryScaleLayers[territoryScale.typeId].addLayer(layer);
    }

    removeTerritoryLabeledLayer = (territoryScale: TerritoryScale) => {
        const featureGroup = this.getLayersByTerritoryScaleType(territoryScale, true);

        territoryScale.isLabelPloted = false;
        featureGroup.clearLayers();
    };

    async addTerritoryLabeledLayer(territoryScale: TerritoryScale) {
        try {
            this.loaderService.start('drawTerritory');
            const data = {
                perimeterScaleTypeId: this.territoryScale.typeId,
                perimeterTerritoryIds: this.territories.map((t) => t.id),
                granularityScaleTypeId: territoryScale.typeId,
                year: this.geoYear,
                shape: 'point',
                isSimplified: territoryScale.type == 'ter_99',
            };
            const geojson = await this.restService.getGeojson(data);
            this.setLabeledLayer(territoryScale, geojson);
            this.loaderService.stop('drawTerritory');
        } catch (error) {
            console.error('Error addTerritoryLabeledLayer', error);
            this.loaderService.stop('drawTerritory');
            throw new Error(error);
        }
    }

    setLabeledLayer(territoryScale: TerritoryScale, geojson: any) {
        const style = territoryScale.style;
        style.weight = 2;

        const layer = L.geoJson(geojson, {
            style: style,
            pointToLayer: (feature: any, latlng: L.LatLng) => {
                return L.marker(latlng, {
                    icon: L.divIcon({
                        className: 'label',
                        html: `
                            <div style="color: ${style.color}; opacity: ${style.opacity};">
                                ${feature.properties.lib_ter}
                            </div>
                            `,
                        iconSize: [100, 40],
                    }),
                });
            },
        });

        territoryScale.isLabelPloted = true;
        this.territoryScaleLabeledLayers[territoryScale.typeId].addLayer(layer);
    }

    updateLayerStyle(territoryScale: TerritoryScale) {
        const style = territoryScale.style;
        const featureGroup = this.getLayersByTerritoryScaleType(territoryScale);
        featureGroup.setStyle(style);
    }

    clearTerritoryLayers() {
        for (let typeId in this.territoryScaleLayers) {
            this.territoryScaleLayers[typeId].clearLayers();
        }
        for (let typeId in this.territoryScaleLabeledLayers) {
            this.territoryScaleLabeledLayers[typeId].clearLayers();
        }
    }

    fitLayerBounds() {
        const typeId = this.territoryScale.typeId;
        this.mapService.fitBounds(this.territoryScaleLayers[typeId].getBounds());
    }

    flyToLayerBounds() {
        const typeId = this.territoryScale.typeId;
        this.mapService.flyToBounds(this.territoryScaleLayers[typeId].getBounds(), true);
    }

    flyToLabeledLayerBounds() {
        const typeId = this.territoryScale.typeId;
        this.mapService.flyToBounds(this.territoryScaleLabeledLayers[typeId].getBounds(), true);
    }

    getUserMinScale() {
        const userMinScale = this.territoryScales.reduce(
            (minGranularity, scale) =>
                !minGranularity || scale.order < minGranularity.order ? scale : minGranularity,
            null,
        );
        return userMinScale;
    }

    getUserMaxScale() {
        const userMaxScale = this.territoryScales.reduce(
            (maxGranularity, scale) =>
                !maxGranularity || scale.order > maxGranularity.order ? scale : maxGranularity,
            null,
        );
        return userMaxScale;
    }

    async getGetTerritorryLabelByLocation(
        latitude: number,
        longitude: number,
        year?: number,
        scaleTypeId?: number,
    ) {
        if (!year) {
            year = this.territoryScale.year;
        }

        if (!scaleTypeId) {
            scaleTypeId = this.territoryScale.typeId;
        }
        const parameters = {
            scaleTypeId: scaleTypeId,
            year: year,
            latitude: latitude,
            longitude: longitude,
        };
        const data: TerritorryByLocationRest =
            await this.restService.getGetTerritorryLabelByLocation(parameters);

        return data;
    }
}

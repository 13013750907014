import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';

import { CadastreSolaireInfoModalComponent } from '../../modals/info/cadastre-solaire-info-modal.component';

@Component({
    selector: 'cadastre-solaire-result-card',
    templateUrl: './cadastre-solaire-result-card.template.html',
    styleUrls: ['./cadastre-solaire-result-card.component.scss'],
})
export class CadastreSolaireResultCardComponent implements OnDestroy {
    @Input() icon: string;
    @Input() iconSvg: string;
    @Input() titleLabel: string;
    @Input() contentHtml: string;
    @Input() modalContentHtml: string;
    @Input() customClass: string;
    @Input() connectionCost: any;
    @Input() valorization: string;

    private connectionCostClickSubject = new Subject<void>();
    public connectionCostClick$ = this.connectionCostClickSubject.asObservable();

    constructor(
        private modalService: NgbModal,
        @Inject(SolarService) public solarService: SolarService,
    ) {}

    ngOnDestroy() {
        this.connectionCostClickSubject.next();
        this.connectionCostClickSubject.complete();
    }

    openInfoModal(title: string, body: string) {
        if (this.valorization == 'Surplus' && title == 'Production') {
            body +=
                "Le taux d'autoconsommation représente la part de l'électricité produite par les panneaux solaires qui sera directement consommée au sein du logement.";
        }

        this.modalService.dismissAll();
        const modalRef = this.modalService.open(CadastreSolaireInfoModalComponent, { size: 'xs' });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = body;
    }

    async getConnectionCost() {
        this.connectionCostClickSubject.next();
    }
}

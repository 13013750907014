<div
    id="tutorialModal"
    tabindex="-1"
    aria-labelledby="tutorialModal"
    aria-modal="true"
    class="tutorial"
>
    <div class="modal-header cursor-default">
        <h4 class="modal-title">Tutoriel</h4>
    </div>

    <div class="modal-body cursor-default">
        <div class="container-fluid">
            <div class="row g-3">
                <div class="col-xl-6 d-flex flex-column justify-content-center">
                    <img
                        src="../../../../../assets/img/cadastre-solaire/tutorial_cropped.png"
                        alt="tutorial"
                        width="100%"
                    />
                </div>
                <div class="col-xl-6">
                    <h4 class="text-inherit">
                        Une fois votre adresse renseignée, vous êtes dirigés vers l'interface
                        ci-contre.
                    </h4>
                    <ul class="list-group mb-0">
                        <li class="list-group-item border-0 ps-0 no-bg">
                            1 - Reportez-vous à la légende pour visualiser votre potentiel et
                            sélectionner votre toiture : du bleu (le moins productif) au rouge (le
                            plus productif).
                        </li>
                        <li class="list-group-item border-0 ps-0 no-bg">
                            2 - Cliquez sur votre toiture et visualisez un bref descriptif de sa
                            capacité de production.
                        </li>
                        <li class="list-group-item border-0 ps-0 no-bg">
                            3 - Démarrez une simulation de projet : soit en production électrique
                            soit en production d'eau chaude sanitaire, et sélectionnez le mode de
                            valorisation de l'énergie : vente totale ou autoconsommation.
                        </li>
                        <li class="list-group-item border-0 ps-0 no-bg">
                            4 - Vous pouvez modifier la surface estimée à la hausse ou à la baisse
                            et obtenir une nouvelle estimation en cliquant sur "Personnaliser la
                            surface disponible".
                        </li>
                        <li class="list-group-item border-0 ps-0 pb-0 no-bg">
                            5 - Il est possible d'additionner différentes toitures en cochant
                            l'option "Sélectionner plusieurs toitures ou pans de toiture".
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <stButton
            btnLabel="Fermer"
            btnClass="btn-st-active"
            (click)="activeModal.dismiss()"
        ></stButton>
    </div>
</div>

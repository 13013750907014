<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center solar-card">
                        <div class="col-auto px-0 text-center">
                            <span class="fa-stack">
                                <i class="fa fa-circle-thin fa-stack-2x"></i>
                                <i class="fa fa-info fa-stack-1x"></i>
                            </span>
                        </div>
                        <div class="col">
                            <div [innerHtml]="equipmentHeaderPv"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <div class="container-fluid solar-card building-description mb-3">
                    <h5 class="text-st-primary mb-3">Je décris mon bâtiment</h5>

                    <div class="row d-flex align-items-center">
                        <label
                            for="typology"
                            class="col-sm control-label text-start fw-normal py-0"
                        >
                            Mon bâtiment
                        </label>
                        <div class="col-sm-5">
                            <stSelect
                                innerId="typology"
                                [(ngModel)]="solarPvSurplusService.typologyData.value"
                                [data]="typologyOptions"
                                labelField="label"
                                valueField="value"
                                type="primary"
                                [isDisabled]="!modularity.importLinky"
                            >
                            </stSelect>
                        </div>
                    </div>

                    <ng-container *ngIf="solarPvSurplusService.typologyData.value == 'housing'">
                        <form [formGroup]="housingFormGroup">
                            <div class="row d-flex align-items-center mb-3">
                                <label
                                    for="occupant-count"
                                    class="col-sm control-label text-start fw-normal"
                                >
                                    Nombre d'occupants
                                </label>
                                <div class="col-sm-5">
                                    <stInput
                                        formControlName="occupantCount"
                                        innerId="occupant-count"
                                        class="primary"
                                        type="number"
                                        [min]="1"
                                    >
                                    </stInput>
                                </div>
                            </div>

                            <div class="row d-flex align-items-center">
                                <label
                                    for="housing-area"
                                    class="col-sm control-label text-start fw-normal"
                                >
                                    Surface habitable
                                </label>
                                <div class="col-sm-5">
                                    <div class="row d-flex align-items-center">
                                        <div class="col">
                                            <stInput
                                                formControlName="usableFloorArea"
                                                innerId="housing-area"
                                                class="primary"
                                                type="number"
                                                [min]="1"
                                            >
                                            </stInput>
                                        </div>
                                        <div class="col-auto">m<sup>2</sup></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                </div>

                <div
                    class="container-fluid solar-card"
                    *ngIf="solarPvSurplusService.typologyData.value == 'housing'"
                >
                    <h5 class="text-st-primary mb-3">Je décris mes équipements</h5>

                    <div id="uses-list">
                        <ng-container *ngFor="let equipment of equipments">
                            <div class="card h-100 w-100">
                                <div
                                    class="card-header card-equipment"
                                    [ngClass]="{'equipment-active': equipment.active}"
                                >
                                    <div class="row d-flex justify-content-between">
                                        <div class="col d-flex align-items-center">
                                            <stCheckbox
                                                class="check-equipment"
                                                innerId="cs-housing-{{ equipment.value }}"
                                                labelClass="d-flex align-items-center"
                                                inputClass="p-2"
                                                [(ngModel)]="equipment.active"
                                                [trueValue]="true"
                                                [falseValue]="false"
                                            >
                                                <span class="px-2"> {{ equipment.label }} </span>
                                            </stCheckbox>
                                        </div>
                                        <div class="col-auto d-flex align-items-center">
                                            <stButton
                                                class="btn-info-modal"
                                                btnClass="d-flex align-items-center py-0"
                                                iconLeft="bi bi-info-circle-fill text-st-secondary fs-5"
                                                iconLeftHover="bi bi-info-circle fs-5"
                                                (onClick)="openInfoModal(equipment.message, equipment.label)"
                                                title="Plus d'information"
                                            ></stButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div
                        id="import-consumption"
                        class="container-fluid px-0 mt-1"
                        *ngIf="modularity.importLinky && !showMobileUi"
                    >
                        <div class="row d-flex align-items-center justify-content-center">
                            <div class="col-2">
                                <hr />
                            </div>
                            <div class="col-auto text-center">ou</div>
                            <div class="col-2">
                                <hr />
                            </div>

                            <stButton
                                btnClass="btn-st-default w-100"
                                btnLabel="J'importe mes consommations réelles grâce à mon compteur Linky"
                                (onClick)="goToStage('importStage')"
                            >
                            </stButton>
                        </div>
                    </div>
                </div>

                <div id="tertiary" *ngIf="solarPvSurplusService.typologyData.value == 'tertiary'">
                    <div class="solar-card mb-3">
                        Pour estimer au mieux vos possibilités en autoconsommation, veuillez
                        importer votre courbe de charges depuis le site de votre distributeur
                        d’électricité.
                    </div>

                    <stButton
                        btnClass="btn-st-primary w-100"
                        btnLabel="J'importe mes consommations réelles grâce à mon compteur Linky"
                        (onClick)="goToStage('importStage')"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>

    <div class="footer border-top">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-5">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-default w-100 h-100"
                        btnLabel="Retour"
                        (onClick)="cancel()"
                    >
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        btnLabel="Valider mes équipements"
                        [isDisabled]="!isHousingFormValid || solarPvSurplusService.typologyData.value == 'tertiary'"
                        (onClick)="validate()"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>

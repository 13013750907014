import { Component, Inject, OnInit, PipeTransform, QueryList, ViewChildren } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ReversePipe } from 'ngx-pipes';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Group } from 'src/app/models/User';

import { RestService } from 'src/app/services/RestService';
import { StTableService } from '../../../components/st-table/st-table.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/components/st-table/sortable.directive';

import { ConfirmationModalComponent } from '../../main/modals/confirmation/confirmation-modal.component';

function matches(group: any, term: string, pipe: PipeTransform) {
    const lowerCaseTerm = term.toLowerCase();
    return (
        pipe.transform(group.id).includes(term) ||
        group.name.toLowerCase().includes(lowerCaseTerm) ||
        group.territories.includes(lowerCaseTerm) ||
        group.granularity.toLowerCase().includes(lowerCaseTerm) ||
        group.referent_name.toLowerCase().includes(lowerCaseTerm)
    );
}

@Component({
    selector: 'manageGroup',
    templateUrl: './group-manage.template.html',
    providers: [ReversePipe],
    styleUrls: [
        '../../../components/st-table/st-table.component.scss',
        './group-manage.controller.scss',
    ],
})
export class ManageGroupComponent implements OnInit {
    groups$: Observable<any[]>;
    total$: Observable<number>;
    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

    public totalUserCount = 0;
    public meanUserCount = 0;

    public groups = [];
    public group: { id: any };
    public header = {};
    public body = [];

    constructor(
        private notification: ToastrService,
        private router: Router,
        private modalService: NgbModal,
        @Inject(RestService) private restService: RestService,
        @Inject(StTableService) public stTableService: StTableService,
    ) {
        this.groups$ = this.stTableService.rows$;
        this.total$ = this.stTableService.total$;
    }

    async ngOnInit(): Promise<void> {
        this.initialization();
        this.stTableService.matches = matches;
    }

    private async initialization() {
        try {
            const groupsInfo = await this.restService.getSimpleAllGroups();
            this._createDataForTable(groupsInfo);
        } catch (error) {
            console.error('Error initialization', error);
        }
    }

    goToUpdateGroup(group: Group) {
        this.router.navigate(['administration/groups/update/' + group.id + '/']);
    }

    goToNewGroup() {
        this.router.navigate(['administration/groups/add/']);
    }

    private _createDataForTable(groupsInfo: Group[]) {
        this.groups = groupsInfo;
        this._setGroups();

        this.totalUserCount = this.groups.reduce((total, group) => total + group.user_count, 0);
        this.meanUserCount =
            this.groups.length > 0
                ? (this.meanUserCount = Math.round(this.totalUserCount / this.groups.length))
                : 0;

        this.stTableService.setRows(groupsInfo);
    }

    setTerritoryLabel(territories: string[]) {
        return territories.map((t) => t).join(', ');
    }

    deleteGroup(groupInfo: Group) {
        const modalHeader = 'Attention !';
        const modalBody = `
            <div>Voulez-vous vraiment supprimer ce groupe ?</div>
            <div>Cette action sera irréversible et supprimera également les utilisateurs du groupe !</div>
        `;

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.header = modalHeader;
        modalRef.componentInstance.body = modalBody;
        modalRef.result.then(
            (result) => this._validateDelete(groupInfo),
            () => {},
        );
    }

    private async _validateDelete(groupInfo: Group) {
        try {
            const groups = await this.restService.deleteGroup(groupInfo.id);
            this._createDataForTable(groups);
            this.stTableService.setRows(this.groups);
            this.notification.success(`Le groupe "${groupInfo.name}" a été supprimé.`);
        } catch (error) {
            console.error('Error deleteUser', error);
            this.notification.error('Une erreur est survenue, veuillez rééssayer.');
        }
    }

    onSort({ column, direction }: SortEvent) {
        // resetting other headers
        this.headers.forEach((header) => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.stTableService.sortColumn = column;
        this.stTableService.sortDirection = direction;
    }

    private _setGroups() {
        this.groups.forEach((group) => {
            // group.name = group.group.nom;
            // group.referent = group.group.referent;

            const endOfRightsDate = new Date(group.limit_validity_date);
            group.endOfRightsDate = endOfRightsDate.toLocaleDateString();
            group.endOfRightsDateTime = endOfRightsDate.getTime();
        });
    }
}

<div class="solar-card p-0 plot">
    <stButton
        id="btn-collapse-production"
        [btnLabel]="labelCollapseProduction"
        btnClass="btn-sm w-100 rounded-0"
        (onClick)="collapseProductionPlot()"
        [isCollapseButton]="true"
        [isCollapsed]="!activeCollapseProduction"
    ></stButton>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="!activeCollapseProduction">
        <div class="p-3">
            <div class="st-pills">
                <ul ngbNav #nav="ngbNav" [activeId]="activeTabId">
                    <li [ngbNavItem]="1">
                        <a
                            ngbNavLink
                            (click)="showTypicalDayPlot()"
                            class="h-100 d-flex align-items-center justify-content-center"
                            >Journée type</a
                        >
                        <ng-template ngbNavContent>
                            <cadastreSolairePvSurplusTypicalDay></cadastreSolairePvSurplusTypicalDay>
                        </ng-template>
                    </li>

                    <li [ngbNavItem]="2">
                        <a
                            ngbNavLink
                            (click)="showTypicalYearPlot()"
                            class="h-100 d-flex flex-column align-items-center justify-content-center"
                        >
                            Année type
                        </a>
                        <ng-template ngbNavContent>
                            <cadastreSolairePvSurplusMonthly></cadastreSolairePvSurplusMonthly>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div [ngbNavOutlet]="nav"></div>

            <ng-container *ngIf="!showMobileUi">
                <stButton
                    *ngIf="!isDownloading"
                    btnClass="btn-outline-st-primary w-100"
                    btnLabel="Exporter les données des graphiques"
                    (onClick)="downloadExcelResults()"
                ></stButton>
                <stButton
                    *ngIf="isDownloading"
                    btnClass="btn-outline-st-active w-100"
                    btnLabel="Veuillez patienter"
                    iconRight="fa fa-spinner fa-pulse ms-2"
                    [isDisabled]="isDownloading"
                ></stButton>
            </ng-container>
        </div>
    </div>
</div>

import { Component, Input } from '@angular/core';

@Component({
    selector: 'st-svg',
    templateUrl: './st-svg.template.html',
    styleUrls: ['./st-svg.component.scss'],
})
export class SvgComponent {
    @Input() svgName: string;
    @Input() size: number = 24;
    @Input() color: string = 'currentColor';

    public svgContent: string;

    ngOnInit() {
        this.loadSvg();
    }

    private async loadSvg() {
        const response = await fetch(`/assets/img/svg/${this.svgName}.svg`);
        this.svgContent = await response.text();
    }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReversePipe, RangePipe } from 'ngx-pipes';
import { ToastrService } from 'ngx-toastr';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Indicator } from 'src/app/models/Indicator';

import { ConfirmationModalComponent } from 'src/app/controllers/main/modals/confirmation/confirmation-modal.component';
import { MapParametersModalComponent } from 'src/app/controllers/main/components/module-indicator-parameters/modals/parameters-modal.component';

import { DashboardService } from 'src/app/services/dashboard.service';
import { DataService } from 'src/app/services/DataService';
import { DistributionValueService } from 'src/app/services/plotIndicator/distributions/DistributionValueService';
import { EventService } from 'src/app/services/event.service';
import { LoaderService } from 'src/app/services/LoaderService';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ManualDistribution } from 'src/app/services/plotIndicator/distributions/methods/Manual';
import { ModuleService } from 'src/app/services/module.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';

@UntilDestroy()
@Component({
    selector: 'indicator-parameters',
    templateUrl: './indicator-parameters.template.html',
    styleUrls: ['./indicator-parameters.component.scss'],
    providers: [ReversePipe, RangePipe],
})
export class IndicatorParametersComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    public user = this.localStorageService.get('user');
    public modularity: {
        legend: boolean;
        shape: boolean;
        border: boolean;
        saving_management: boolean;
    };
    public indicatorsData: Indicator[] = this.dataService.activeIndicatorsList;
    public indicatorPlot: Indicator;
    public indicatorData: Indicator = null;
    public gradientColorInfo = {};
    public showSetDefaultParametersModal = false;
    public savingLevel = 'user';

    public manageMaxClassCount = {
        value: null,
    };

    public manageShape = {
        value: null,
        options: {
            cluster: [
                { value: 'point_proportional', label: 'Cercle proportionnel' },
                { value: 'cluster', label: 'Groupée (Cluster)' },
                { value: 'point', label: 'Point' },
                // { value: 'heatmap', label: 'Carte de chaleur' },
                // { value: 'chart', label: 'Camembert' },
                // { value: 'icon', label: 'Icone' },
            ],
            point: [
                { value: 'point_proportional', label: 'Cercle proportionnel' },
                { value: 'point', label: 'Point' },
                { value: 'poly', label: 'Polygone' },
                // { value: 'point_proportional', label: 'Cercle proportionnel' },
                // { value: 'heatmap', label: 'Carte de chaleur' },
                // { value: 'cluster', label: 'Groupée (Cluster)' },
                // { value: 'chart', label: 'Camembert' },
                // { value: 'icon', label: 'Icone' },
            ],
            point_proportional: [
                { value: 'point_proportional', label: 'Cercle proportionnel' },
                { value: 'point', label: 'Point' },
                { value: 'poly', label: 'Polygone' },
                // { value: 'heatmap', label: 'Carte de chaleur' },
                // { value: 'cluster', label: 'Groupée (Cluster)' },
                // { value: 'chart', label: 'Camembert' },
                // { value: 'icon', label: 'Icone' },
            ],
            poly: [
                { value: 'point_proportional', label: 'Cercle proportionnel' },
                { value: 'point', label: 'Point' },
                { value: 'poly', label: 'Polygone' },
            ],
            point_poste_10: [
                { value: 'point_proportional', label: 'Cercle proportionnel' },
                { value: 'point_poste_10', label: 'point_poste_10' },
                // { value: 'heatmap', label: 'Carte de chaleur' },
                // { value: 'cluster', label: 'Groupée (Cluster)' },
                // { value: 'chart', label: 'Camembert' },
                // { value: 'icon', label: 'Icone' },
            ],
            point_poste_20: [
                { value: 'point_proportional', label: 'Cercle proportionnel' },
                { value: 'point_poste_20', label: 'point_poste_20' },
                // { value: 'heatmap', label: 'Carte de chaleur' },
                // { value: 'cluster', label: 'Groupée (Cluster)' },
                // { value: 'chart', label: 'Camembert' },
                // { value: 'icon', label: 'Icone' },
            ],
            heatmap: [
                { value: 'point', label: 'Point' },
                { value: 'point_proportional', label: 'Cercle proportionnel' },
                { value: 'heatmap', label: 'Carte de chaleur' },
                { value: 'cluster', label: 'Groupée (Cluster)' },
                { value: 'chart', label: 'Camembert' },
                { value: 'icon', label: 'Icone' },
            ],
            chart: [
                { value: 'point', label: 'Point' },
                { value: 'point_proportional', label: 'Cercle proportionnel' },
                { value: 'heatmap', label: 'Carte de chaleur' },
                { value: 'cluster', label: 'Groupée (Cluster)' },
                { value: 'chart', label: 'Camembert' },
                { value: 'icon', label: 'Icone' },
            ],
            line: [{ value: 'line', label: 'Ligne' }],
            icon: [
                { value: 'icon', label: 'Icone' },
                { value: 'point', label: 'Point' },
                // { value: 'point_proportional', label: 'Cercle proportionnel' },
                // { value: 'heatmap', label: 'Carte de chaleur' },
                // { value: 'cluster', label: 'Groupée (Cluster)' },
                // { value: 'chart', label: 'Camembert' },
            ],
        },
    };

    public manageSortLegendBy = {
        value: null,
        options: [
            {
                label: 'Défaut',
                value: 'default',
            },
            {
                label: 'Fréquence croissante',
                value: 'asc',
            },
            {
                label: 'Fréquence décroissante',
                value: 'desc',
            },
        ],
    };

    constructor(
        private modalService: NgbModal,
        private notification: ToastrService,
        private rangePipe: RangePipe,
        @Inject(DashboardService) private dashboardService: DashboardService,
        @Inject(DataService) private dataService: DataService,
        @Inject(DistributionValueService)
        public distributionValueService: DistributionValueService,
        @Inject(EventService) private eventService: EventService,
        @Inject(LoaderService) private loaderService: LoaderService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(ManualDistribution) public manualDistribution: ManualDistribution,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
    ) {}

    async ngOnInit(): Promise<void> {
        const preferences = this.localStorageService.get('preferences');
        this.modularity = preferences.modularity.indicatorParameters;

        this.eventService.indicatorPloted
            .pipe(untilDestroyed(this))
            .subscribe((indicatorId: any) => {
                this.indicatorsData = this.dataService.activeIndicatorsList;
                this.indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
                this.indicatorData = this.dataService.activeIndicators[indicatorId];
                this._initialization();
            });

        this.eventService.indicatorUnploted.pipe(untilDestroyed(this)).subscribe(() => {
            const indicatorPlots: Indicator[] = Object.values(
                this.plotIndicatorService.plotedIndicators,
            );
            if (indicatorPlots.length > 0) {
                const indicatorId = indicatorPlots[0].indicatorId;
                this.indicatorPlot = indicatorPlots[0];
                this.indicatorsData = this.dataService.activeIndicatorsList;
                this.indicatorData = this.dataService.activeIndicators[indicatorId];
                this._initialization();
            } else {
                this.indicatorsData = [];
                this.indicatorPlot = null;
                this.indicatorData = null;
            }
        });

        this.moduleService.indicatorIdSelectedObs$
            .pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((newIndicatorId) => {
                if (newIndicatorId) {
                    this.changeSelectedIndicator(newIndicatorId);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _initialization() {
        this._setShape();
        this._setSortLegendBy();
        this._updateLevelAvailable();
        for (let id in this.plotIndicatorService.plotedIndicators) {
            this._setAvailableColorGradient(Number(id));
        }
    }

    private _setShape() {
        const currentShapeName = this.indicatorPlot.form;

        this.manageShape.value = this.manageShape.options[currentShapeName].find(
            (s: any) => s.value == currentShapeName,
        ).value;
    }

    private _setSortLegendBy() {
        if (this.indicatorPlot.type == 'class' && this.indicatorPlot.classParameters) {
            this.manageMaxClassCount.value = Number(
                this.indicatorPlot.classParameters.max_class_count,
            );
            const currentSortLegendBy = this.indicatorPlot.classParameters.sort_legend_by;

            this.indicatorPlot.classParameters.sort_legend_by =
                this.manageSortLegendBy.options.find(
                    (option: any) => option.value == currentSortLegendBy,
                ).value;
        }
    }

    private _updateLevelAvailable() {
        const hasMultipleLevels = this.indicatorData.levelAvailable.length > 1;
        this.showSetDefaultParametersModal = hasMultipleLevels;
    }

    private _setAvailableColorGradient(indicatorId: number) {
        this.gradientColorInfo[indicatorId] =
            this.plotIndicatorService.setAvailableColorGradient(indicatorId);
    }

    hasMultipleDataToPlot(): boolean {
        // return Object.keys(this.indicatorPlot.dataToPlot).length > 1;
        return this.indicatorPlot.geojson.features.length > 1;
    }

    changeSelectedIndicator(indicatorId: number) {
        this.indicatorData = this.dataService.activeIndicators[indicatorId];
        this.indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
    }

    isActiveIndicatorsAvailable() {
        const isDataIndicatorsAvailable = this.dataService.activeIndicatorsList.length > 0;
        return isDataIndicatorsAvailable;
    }

    getAvailableMethod() {
        return this.distributionValueService.methodsInfo;
    }

    refreshLayer(refreshIntervals: boolean = true) {
        // check manual distribution method before updating
        const distributionType = this.indicatorPlot.distribution.type;
        if (distributionType == 'value') {
            const methodId = this.indicatorPlot.distribution.method.id;
            if (methodId == 'manuel') {
                if (!this._isManualDistributionValid()) return;
                const decimalCount = this.indicatorPlot.decimalCount;
                const legendBoundaries = this.indicatorPlot.legendBoundaries;
                legendBoundaries
                    .slice(0, -1)
                    .forEach(
                        (boundary, index) =>
                            (boundary[1] =
                                legendBoundaries[index + 1][0] - 1 / Math.pow(10, decimalCount)),
                    );
            }
        }

        this.plotIndicatorService.updateParameters(this.indicatorPlot, refreshIntervals);
        this.plotIndicatorService.refreshLayer(this.indicatorPlot);

        const indicatorId = this.indicatorPlot.indicatorId;
        this.dashboardService.updateDashboardTableRepartition(indicatorId);
    }

    updateColorGradient(newColorGradient: string) {
        this.indicatorPlot.newColorGradient = newColorGradient;
        this.refreshLayer(false);
    }

    async updateShape() {
        if (this.manageShape.value == this.indicatorPlot.form) {
            return;
        }
        await this.updateIndicatorShape(this.manageShape.value);
    }

    async updateIndicatorShape(newShape: string) {
        this.loaderService.start('updateIndicatorShape');
        try {
            const indicatorId = this.indicatorPlot.indicatorId;
            await this.plotIndicatorService.updateIndicatorShape(indicatorId, newShape);
        } catch (error) {
            console.error('Error updateShape', error);
            this.notification.error(
                `Une erreur est surevenue. Impossible de mettre à jour l'indicateur.`,
            );
        } finally {
            this.loaderService.stop('updateIndicatorShape');
        }
    }

    async setClassIndicatorAggregatedValue() {
        this.indicatorPlot.classParameters.max_class_count = Number(this.manageMaxClassCount.value);
        this.plotIndicatorService.setClassIndicatorAggregatedValue(this.indicatorPlot);
        this.refreshLayer();
    }

    updatePattern(option: any) {
        const type = this.indicatorPlot.type;

        if (type == 'valeur') {
            this.indicatorPlot.dashArray = option;
        }

        if (type == 'class') {
            this.indicatorPlot.class_label.forEach((c: any) => (c.dashArray = option));
        }

        this.refreshLayer(false);
    }

    duplicateDashArray(option, width = 100) {
        const toReturn = [];
        while (this.sum(toReturn) < width) {
            toReturn.push(...option);
        }
        return toReturn;
    }

    scrollToBottom() {
        $('.legend-form-border').animate(
            {
                scrollTop: $('.legend-form-border').get(0).scrollHeight,
            },
            1000,
        );
    }

    isPatternSelected(option: Number[]) {
        const type = this.indicatorPlot.type;

        if (type == 'valeur') {
            return JSON.stringify(this.indicatorPlot.dashArray) === JSON.stringify(option);
        }
        if (type == 'class') {
            const legende = this.indicatorPlot.legende;
            const dashArray = legende.map((l: any) => l.dashArray);
            const isSame = dashArray.every(
                (x: string) => JSON.stringify(x) == JSON.stringify(dashArray[0]),
            );
            if (isSame) {
                return JSON.stringify(dashArray[0]) === JSON.stringify(option);
            }
        }
        return false;
    }

    openSaveParametersModal(level = 'user') {
        this.savingLevel = level;

        let body: string;
        if (this.savingLevel == 'user') {
            body = 'Vous vous apprêtez à modifier la valeur par défaut.';
        } else if (this.savingLevel == 'group') {
            body =
                "Vous vous apprêtez à modifier la valeur par défaut pour l'ensemble des utilisateurs de votre groupe d'utilisateurs.";
        } else {
            body =
                "Vous vous apprêtez à modifier la valeur par défaut pour l'ensemble des utilisateurs de Siterre.";
        }

        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.header = 'Êtes vous sur.e ?';
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            () => this._saveNewIndicatorParameters(),
            () => {},
        );
    }

    private async _saveNewIndicatorParameters() {
        this.loaderService.start('saveNewIndicatorParameters');

        try {
            const indicatorId = this.indicatorPlot.indicatorId;
            const type = this.indicatorPlot.type;
            const form = this.indicatorPlot.form;

            const data: any = {
                indicatorId: indicatorId,
                level: this.savingLevel,
                form: this.indicatorPlot.form,
                degrade: this.indicatorPlot.degrade,
                indicatorfillopacity: this.indicatorPlot.indicatorfillopacity,
                default_icon: this.indicatorPlot.default_icon,
                default_radius_weight: this.indicatorPlot.default_radius_weight,
                indicatorcontourcolor: this.indicatorPlot.indicatorcontourcolor,
                indicatorcontouropacity: this.indicatorPlot.indicatorcontouropacity,
                indicatorcontourweight: this.indicatorPlot.indicatorcontourweight,
                dash_array: this.indicatorPlot.dashArray,
            };

            if (type == 'class') {
                data.id_archi_class = this.indicatorPlot.id_archi_class;
                data.class_label = this.indicatorPlot.customClassParameters;
                data.classParameters = this.indicatorPlot.classParameters;
            } else {
                data.defaut_class_number = this.indicatorPlot.classCount;
                data.defaut_class_method = this.indicatorPlot.distribution.method.id;
                const isMethodManual = this.indicatorPlot.distribution.method.id === 'manuel';

                if (isMethodManual) {
                    data.defaut_class_number = this.indicatorPlot.classCount;
                    const tableBornes =
                        this.manualDistribution.convertLegendBoundariesToTableBornes(
                            this.indicatorPlot.legendBoundaries,
                        );
                    data.defaut_class_method = JSON.stringify(tableBornes);
                }

                if (form == 'line') {
                    data.dash_array = this.indicatorPlot.dashArray.join(',');
                }
            }

            const isLevelAlreadyAvailable = this.indicatorData.levelAvailable.includes(
                this.savingLevel,
            );
            isLevelAlreadyAvailable
                ? await this.dataService.updateIndicatorCustomization(data)
                : await this.dataService.createIndicatorCustomization(data);

            if (!isLevelAlreadyAvailable) {
                this.indicatorData.levelAvailable.push(this.savingLevel);
            }
            this._updateLevelAvailable();

            this.notification.success(`L'indicateur a été mis à jour.`);
        } catch (error) {
            console.error('Error _saveNewIndicatorParameters', error);
            this.notification.error(
                `Une erreur est surevenue. Impossible de mettre à jour l'indicateur.`,
            );
        } finally {
            this.loaderService.stop('saveNewIndicatorParameters');
        }
    }

    openSetDefaultParametersModal() {
        const modalRef = this.modalService.open(MapParametersModalComponent);
        modalRef.componentInstance.levelAvailable = this.indicatorData.levelAvailable;
        modalRef.result.then(
            (level) => this.setDefaultParameters(level),
            () => {},
        );
    }

    async setDefaultParameters(level: string) {
        this.loaderService.start('setDefaultParameters');

        try {
            const indicatorId = this.indicatorPlot.indicatorId;
            const type = this.indicatorPlot.type;
            const currentShape = this.indicatorPlot.form;

            const parameters = {
                level: level,
            };
            this.indicatorData = await this.dataService.updateIndicator(indicatorId, parameters);
            this.plotIndicatorService.setAttributes(this.indicatorPlot);

            if (type == 'class') {
                this.indicatorPlot.distributionService.cleanClassLabel(this.indicatorPlot);
            } else {
                this.indicatorPlot.distributionService.cleanLineForm(this.indicatorPlot);
            }

            this.indicatorPlot.distributionService.init(this.indicatorPlot);
            this.refreshLayer();
            this._setAvailableColorGradient(indicatorId);

            const defaultShape = this.indicatorData.form;
            if (defaultShape != currentShape) {
                await this.updateIndicatorShape(defaultShape);
            }

            this.notification.success(`L'indicateur a été mis à jour.`);
        } catch (error) {
            console.error('Error setDefaultParameters', error);
            this.notification.error(
                `Une erreur est surevenue. Impossible de mettre à jour l'indicateur.`,
            );
        } finally {
            this.loaderService.stop('setDefaultParameters');
        }
    }

    private _isManualDistributionValid() {
        const legendBoundaries = this.indicatorPlot.legendBoundaries;
        legendBoundaries.forEach((boundary) => {
            boundary[0] = Number(boundary[0]);
            boundary[1] = Number(boundary[1]);
        });

        const isSorted =
            JSON.stringify(legendBoundaries) ==
            JSON.stringify(legendBoundaries.sort((a: number[], b: number[]) => a[0] - b[0]));
        const isGreaterThanFirst =
            Math.min(...legendBoundaries.slice(1).map((boundary) => boundary[0])) >
            legendBoundaries[0][0];
        const isSmallerThanLast =
            Math.max(
                ...legendBoundaries
                    .slice(0, legendBoundaries.length - 1)
                    .map((boundary) => boundary[0]),
            ) < legendBoundaries[legendBoundaries.length - 1][1];

        return isSorted && isGreaterThanFirst && isSmallerThanLast;
    }

    sum(array: Array<number>) {
        return array.reduce((prev, curr) => prev + curr, 0);
    }

    getRange(nbr: number) {
        const range = this.rangePipe.transform(0, nbr);
        return range;
    }

    search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            map((term) =>
                term.length < 2
                    ? []
                    : this.indicatorPlot.icon_list
                          .filter(
                              (icon: string) => icon.toLowerCase().indexOf(term.toLowerCase()) > -1,
                          )
                          .slice(0, 10),
            ),
        );

    formatter(x: any) {
        return x;
    }

    setManualBornStep(decimalCount: number): number {
        return 1 / Math.pow(10, decimalCount);
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ReversePipe } from 'ngx-pipes';

import { SubTheme, Theme } from 'src/app/models/Theme';
import { Indicator } from 'src/app/models/Indicator';
import { Territory, TerritoryLabel, TerritoryScale } from 'src/app/models/TerritoryTypes';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { TerService } from 'src/app/services/TerService';
import { RestService } from 'src/app/services/RestService';

@Component({
    selector: 'addUser',
    templateUrl: './user-add.template.html',
    providers: [ReversePipe],
})
export class AddUserComponent implements OnInit {
    //$rootScope.isOnAdminPage = true;

    public user = null;
    public isUserSuperAdmin = null;
    public newUser = null;

    public isValidating = false;
    public roles = [
        {
            id: 1,
            label: 'Super Administrateur',
        },
        {
            id: 2,
            label: 'Administrateur local',
        },
        {
            id: 3,
            label: 'Utilisateur',
        },
    ];

    public territoryScales = [];
    public selectedTerritoryScale: any['type'] = [];
    public selectedGranularity: any['type'] = [];
    public selectedTerritories = [];
    public granularities = [];
    public territories = [];
    public selectedIndicatorIds = [];

    public indicatorsByTheme = [];
    public isRoleInitialized = null;
    public mailCliked = null;
    public _mailFG: { _mail: any } = null;
    public territoryInfo = null;

    public groups: any;
    public selectedRole: { id: number; label: string };
    public selectedGroup: { id: any; territory: any };

    constructor(
        private notification: ToastrService,
        private router: Router,
        @Inject(TerService) private terService: TerService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(RestService) private restService: RestService,
    ) {}

    ngOnInit(): void {
        this.initMain();
    }

    // ##################################### Initialization ##########################################################

    async initMain() {
        this.initGeneralInfo();
        try {
            await this.initTerritory();
            await this.initRoleGroup();
            this.isRoleInitialized = true;
            this.initIndicators();
        } catch (error) {
            console.error('Error initMain', error);
        }
    }

    // ##################################### General Info ##########################################################
    initGeneralInfo() {
        this.user = this.localStorageService.get('user');
        this.isUserSuperAdmin = this.localStorageService.get('user').role == 1;
        this.newUser = {
            surname: '',
            name: '',
            mail: '',
            login: '',
        };
    }

    public changeActiveIndicator = (indicator) => {};

    // ##################################### Territory ##########################################################
    async initTerritory() {
        try {
            await this.setTerritoryScales();
            this.territoryScales = this.terService.territoryScales;
            this.selectedTerritoryScale = this.terService.territoryScale;
            this.selectedTerritories = this.terService.territories;
            this.selectedGranularity = this.terService.granularity;
            return;
        } catch (error) {
            console.error('Error initTerritory', error);
            throw error;
        }
    }

    setTerritoryScales() {
        if (!this.terService.territoryScales) {
            return this.terService.initTerritory(false, false);
        }
        return;
    }

    async selectTerritoryScale() {
        try {
            this.updateGranularities();
            await this.updateTerritories();
        } catch (error) {
            console.error('Error selectTerritoryScale', error);
            throw error;
        }
    }

    async updateTerritories() {
        try {
            this.territories = await this.terService.getTerritoryLabelByYear(
                this.selectedTerritoryScale.year,
                this.selectedTerritoryScale.type,
            );
            this.selectedTerritories = [];
        } catch (error) {
            console.error('Error updateTerritories', error);
            throw error;
        }
    }

    updateGranularities() {
        if (this.selectedTerritoryScale) {
            this.granularities = this.territoryScales.filter(
                (territoryScale: TerritoryScale) =>
                    territoryScale.ordre < this.selectedTerritoryScale.ordre &&
                    territoryScale.geom_poly,
            );
        } else {
            this.granularities = this.territoryScales.filter(
                (territoryScale: TerritoryScale) => territoryScale.geom_poly,
            );
        }
        if (!this.granularities.includes(this.selectedGranularity)) {
            this.selectedGranularity = this.granularities[0];
        }

        if (this.selectedGranularity) {
            let currentGranularityType = this.selectedGranularity.type;
            currentGranularityType =
                currentGranularityType[0] == '0'
                    ? currentGranularityType.slice(1)
                    : currentGranularityType;
            let newTerritoryScaleType = this.selectedTerritoryScale.type;
            newTerritoryScaleType =
                newTerritoryScaleType[0] == '0'
                    ? newTerritoryScaleType.slice(1)
                    : newTerritoryScaleType;

            if (newTerritoryScaleType < currentGranularityType) {
                this.selectedGranularity = null;
            }
        }
    }

    removeTerritory = (index: number) => {
        this.selectedTerritories.splice(index, 1);
    };

    addTerritory = (territoryToAdd: TerritoryLabel) => {
        const alreadyAddedTerritory = this.selectedTerritories.find(
            (territory: any) => territory.id == territoryToAdd.id,
        );

        if (!alreadyAddedTerritory) {
            const id = territoryToAdd.id;
            const label = territoryToAdd.label;
            const newSelectedTerritory = {
                id: id,
                label: label,
                labelId: `${label} (${id})`,
            };
            this.selectedTerritories.push(newSelectedTerritory);
        }
    };

    // ##################################### Role & Group ##########################################################
    async initRoleGroup() {
        console.log('start initRoleGroup');
        this.initRole();
        try {
            this.groups = await this.restService.getAllGroups();
            this.initGroup();
        } catch (error) {
            console.error('Error initGroupRole', error);
            throw error;
        }
    }

    initRole() {
        this.roles = this.roles.filter((role: any) => role.id >= this.user.role);
        this.selectedRole = this.roles[0];
    }

    initGroup() {
        this.selectedGroup = this.groups.find((group: any) => group.id == this.user.group.id);

        const territoryScaleType = this.selectedGroup.territory.territoryScale.type;
        const granularityType = this.selectedGroup.territory.granularity.type;

        this.selectedTerritoryScale = this.terService.territoryScales.find(
            (t: TerritoryScale) => t.type == territoryScaleType,
        );
        this.selectedTerritories = this.selectedGroup.territory.territories;
        this.selectedGranularity = this.terService.territoryScales.find(
            (t: TerritoryScale) => t.type == granularityType,
        );
    }

    async selectRole() {
        this.selectedTerritoryScale = null;
        this.selectedTerritories = [];
        this.selectedGranularity = null;

        let territoryScaleType: string;
        let territories: Territory[];
        let granularityType: string;

        if (this.selectedRole.id === 1) {
            territoryScaleType = 'ter_99';
            territories = [
                {
                    id: '1',
                    label: 'France',
                },
            ];
            granularityType = 'ter_01';
        } else {
            territoryScaleType = this.selectedGroup.territory.territoryScale.type;
            territories = this.selectedGroup.territory.territories;
            granularityType = this.selectedGroup.territory.granularity.type;
        }

        const territoryScale = this.territoryScales.find(
            (territoryScale: TerritoryScale) => territoryScale.type === territoryScaleType,
        );

        const granularity = this.territoryScales.find(
            (territoryScale: TerritoryScale) => territoryScale.type === granularityType,
        );

        this.selectedTerritoryScale = territoryScale;

        try {
            await this.selectTerritoryScale();
            this.selectedTerritories = territories;
            this.selectedGranularity = granularity;
        } catch (error) {
            console.error('Error selectRole', error);
            throw error;
        }
    }

    selectGroup() {
        const territoryScaleType = this.selectedGroup.territory.territoryScale.type;
        const granularityType = this.selectedGroup.territory.granularity.type;

        this.selectedTerritoryScale = this.terService.territoryScales.find(
            (t: TerritoryScale) => t.type == territoryScaleType,
        );
        this.selectedTerritories = this.selectedGroup.territory.territories;
        this.selectedGranularity = this.terService.territoryScales.find(
            (t: TerritoryScale) => t.type == granularityType,
        );
        this.initIndicators();
    }

    // ##################################### Indicators ##########################################################
    async initIndicators() {
        this.selectedIndicatorIds = [];

        const parameters = {
            groupId: this.selectedGroup.id,
        };

        try {
            this.indicatorsByTheme = await this.restService.getIndicatorByModule(parameters);

            this.indicatorsByTheme.forEach((theme: Theme) => {
                theme.open = false;
                theme.ss_theme.forEach((subTheme: SubTheme) => {
                    subTheme.open = false;

                    subTheme.indicators.forEach(
                        (indicator: Indicator) => (indicator.active = true),
                    );
                    this.selectedIndicatorIds.push(
                        ...subTheme.indicators.map(
                            (indicator: Indicator) => indicator.id_indicateur,
                        ),
                    );
                });
            });

            this.updateSelectionState();
        } catch (error) {
            console.error('Error initIndicators', error);
            throw error;
        }
    }

    selectAllIndicatorFromTheme = (theme: Theme) => {
        theme.ss_theme.forEach((subTheme: SubTheme) =>
            subTheme.indicators.forEach((indicator: Indicator) => {
                indicator.active = theme.full < 2;
                this.manageIndicator(indicator);
            }),
        );
        this.updateSelectionState();
    };

    selectAllIndicatorFromSubTheme = (subTheme: SubTheme) => {
        subTheme.indicators.forEach((indicator: Indicator) => {
            indicator.active = subTheme.full < 2;
            this.manageIndicator(indicator);
        });
        this.updateSelectionState();
    };

    manageIndicator = (indicator: any) => {
        const indicatorId = indicator.id_indicateur;
        if (this.selectedIndicatorIds.includes(indicatorId)) {
            const index = this.selectedIndicatorIds.indexOf(indicatorId);
            this.selectedIndicatorIds.splice(index, 1);
        } else {
            this.selectedIndicatorIds.push(indicatorId);
        }
    };

    updateSelectionState() {
        let p = 0;
        let d = 0;

        this.indicatorsByTheme.forEach((theme: Theme) => {
            d = 0;
            theme.ss_theme.forEach((subTheme: SubTheme) => {
                p = 0;
                p += subTheme.indicators.filter((indicator: Indicator) => indicator.active).length;

                if (p == subTheme.indicators.length) {
                    subTheme.full = 2;
                    d += 1;
                } else if (p > 0) {
                    subTheme.full = 1;
                    d += 0.5;
                } else {
                    subTheme.full = 0;
                }
            });

            if (d == theme.ss_theme.length) {
                theme.full = 2;
            } else if (d > 0) {
                theme.full = 1;
            } else {
                theme.full = 0;
            }
        });
    }

    // ##################################### Validation ##########################################################

    redirectToAdminUsers() {
        this.router.navigate(['administration/users/manage/']);
    }

    async validate() {
        this.isValidating = true;

        const territory = {
            territoryScale: {
                type: this.selectedTerritoryScale.type,
                labelLong: this.selectedTerritoryScale.libelle_long,
                labelShort: this.selectedTerritoryScale.libelle_short,
            },
            territories: this.selectedTerritories,
            granularity: {
                type: this.selectedGranularity.type,
                labelLong: this.selectedGranularity.libelle_long,
                labelShort: this.selectedGranularity.libelle_short,
            },
        };

        const data = {
            generalInfo: this.newUser,
            roleId: this.selectedRole.id,
            groupId: this.selectedGroup.id,
            territory: territory,
            indicators: [],
        };

        if (this.selectedRole.id == 3) {
            data.indicators = this.selectedIndicatorIds;
        }

        try {
            await this.restService.createUser(data);
            this.notification.success(`L'utilisateur "${this.newUser.name}" a été créé.`);
            this.isValidating = false;
            this.router.navigate(['administration/users/manage/']);
        } catch (error) {
            console.error('Error validate', error);
            this.isValidating = false;
            this.notification.error('Une erreur est survenue, veuillez rééssayer.');
        }
    }
}

<div
    id="prosperReseaux"
    tabindex="-1"
    aria-labelledby="prosperReseauxNewElementModal"
    aria-modal="true"
>
    <div class="modal-header">
        <h4 class="modal-title">{{ elementsInfo.label }}</h4>
    </div>
    <div class="modal-body">
        <ng-container *ngFor="let element of elementsInfo.elements; let index = index">
            <ng-container *ngIf="element.parameters.length">
                <h5 class="form-title mt-0">{{ element.label }}</h5>
                <div class="container-fluid">
                    <ng-container *ngFor="let parameter of element.parameters">
                        <div class="row parameter-field py-1">
                            <label
                                for="parameter-{{ parameter.id }}"
                                class="col-7 control-label text-start fw-normal"
                            >
                                {{ parameter.label }}
                            </label>
                            <div class="col-4">
                                <ng-container *ngIf="!parameter.options">
                                    <stInput
                                        class="primary"
                                        innerId="parameter-{{ parameter.id }}"
                                        type="number"
                                        [min]="0"
                                        [step]="parameter.step"
                                        [(ngModel)]="parameter.value"
                                    >
                                    </stInput>
                                </ng-container>
                                <ng-container *ngIf="parameter.options">
                                    <stSelect
                                        [data]="parameter.options"
                                        [(ngModel)]="parameter.value"
                                        labelField="label"
                                        valueField="value"
                                        type="primary"
                                    >
                                    </stSelect>
                                </ng-container>
                            </div>
                            <div class="col-1 px-0 text-center">{{ parameter.unit }}</div>
                        </div>
                    </ng-container>
                    <div
                        class="row parameter-field py-1"
                        *ngIf="element.maxLineLength || element.maxLineLength === ''"
                    >
                        <label class="col-7 control-label text-start fw-normal" for="maxLineLength">
                            <div>Longueur maximale d'un tronçon</div>
                            <div>
                                <i>(Laissez vide pour ne pas découper les tronçons)</i>
                            </div>
                        </label>
                        <div class="col-4">
                            <stInput
                                class="primary"
                                innerId="maxLineLength"
                                type="number"
                                [min]="0"
                                [step]="1"
                                [(ngModel)]="element.maxLineLength"
                            >
                            </stInput>
                        </div>
                        <div class="col-1 px-0 text-center">m</div>
                    </div>
                </div>
                <hr class="separator" *ngIf="index < elementsInfo.elements.length - 1" />
            </ng-container>
        </ng-container>
    </div>
    <div class="modal-footer">
        <stButton
            btnLabel="Annuler"
            btnClass="btn-st-default me-3 px-5"
            (click)="cancel()"
        ></stButton>
        <stButton
            [btnLabel]="elementsInfo.action"
            btnClass="btn-st-primary ms-3 px-5"
            (click)="submit()"
        >
        </stButton>
    </div>
</div>

<ng-container *ngIf="visible">
    <div class="tab-container {{ class }} d-flex flex-column">
        <div class="module-title">
            <h4 class="text-center">{{ label }}</h4>
            <h6 *ngIf="!!subLabel" class="text-center">{{ subLabel }}</h6>

            <div class="close-btn-container" [ngClass]="closePlacement">
                <ng-container *ngIf="closePlacement == 'right'">
                    <stButton
                        btnClass="h-100 w-100 rounded-0"
                        (onClick)="close()"
                        title="Replier"
                        iconLeft="bi bi-chevron-left"
                    ></stButton>
                </ng-container>

                <ng-container *ngIf="closePlacement == 'left'">
                    <stButton
                        btnClass="h-100 w-100 rounded-0"
                        (onClick)="close()"
                        title="Replier"
                        iconLeft="bi bi-chevron-right"
                    ></stButton>
                </ng-container>
            </div>
        </div>

        <div class="position-relative module-body flex-grow-1 overflow-hidden">
            <ng-content></ng-content>
        </div>
    </div>
</ng-container>

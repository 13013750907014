<div class="d-flex flex-column h-100" [attr.data-ui]="ui">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center solar-card p-3">
                        <div class="col-auto px-0 text-center">
                            <span class="fa-stack">
                                <i class="fa fa-circle-thin fa-stack-2x"></i>
                                <i class="fa fa-info fa-stack-1x"></i>
                            </span>
                        </div>
                        <div class="col">
                            <p>
                                Il existe deux technologies principales pour une installation
                                solaire sur votre bâtiment&nbsp;: une installation photovoltaïque
                                vous permet de produire de l’électricité, alors qu’une installation
                                thermique vous permet de produire de l’eau chaude.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <div class="solar-card">
                    <h5 class="text-st-primary mb-3">Avec mes panneaux je souhaite</h5>
                    <div class="st-pills">
                        <ul ngbNav #nav="ngbNav" [activeId]="activeTabId">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink (click)="setTechnology('pv')"
                                    >Produire de l'électricité</a
                                >
                                <ng-template ngbNavContent>
                                    <div class="overflow-hidden">
                                        <img
                                            src="../../../../../../../assets/img/cadastre-solaire/pv_cropped.png"
                                            alt="pv-illustration"
                                            class="solar-illustration"
                                            width="215"
                                        />
                                        <div>
                                            <p>
                                                Les panneaux photovoltaïques sont des dispositifs
                                                qui convertissent la lumière du soleil en
                                                électricité.
                                            </p>
                                            <p>
                                                Cette électricité produite peut être consommée par
                                                les appareils électriques de votre foyer ou être
                                                revendue sur le réseau électrique.
                                            </p>
                                            <p>
                                                Démarrez une simulation pour estimer votre projet
                                                solaire et les économies que vous pourrez générer.
                                            </p>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a ngbNavLink (click)="setTechnology('thermal')"
                                    >Produire de l'eau chaude</a
                                >
                                <ng-template ngbNavContent>
                                    <div class="overflow-hidden">
                                        <img
                                            src="../../../../../../../assets/img/cadastre-solaire/thermal_cropped.png"
                                            alt="thermal-illustration"
                                            width="215"
                                            class="solar-illustration"
                                        />
                                        <div>
                                            <p>
                                                Les panneaux solaires thermiques absorbent la
                                                chaleur du soleil pour élever la température de
                                                l'eau stockée dans un réservoir.
                                            </p>
                                            <p>
                                                L'eau chaude ainsi produite peut être utilisée pour
                                                les besoins domestiques en Eau Chaude Sanitaire
                                                (ECS) tels que la douche ou pour le chauffage.
                                            </p>
                                            <p>
                                                Démarrez une simulation pour estimer votre projet
                                                solaire et les économies que vous pourrez générer.
                                            </p>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer border-top">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-5">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-default w-100 h-100"
                        btnLabel="Retour"
                        (onClick)="cancel()"
                    >
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100 h-100"
                        btnLabel="Valider ma technologie"
                        (onClick)="validate()"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, EventEmitter, Input, Output, Inject, OnInit } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ThemeService } from 'src/app/services/ThemeService';

@UntilDestroy()
@Component({
    selector: 'stButton',
    templateUrl: './st-button.template.html',
    styleUrls: ['./st-button.component.scss'],
})
export class StButtonComponent implements OnInit {
    @Input() btnLabel: string;
    @Input() btnClass: string = '';

    @Input() iconLeft: string;
    @Input() iconLeftHover: string;
    @Input() iconRight: string;
    @Input() iconRightHover: string;

    @Input() iconSvgLeft: string;
    @Input() iconSvgLeftHover: string;
    @Input() iconSvgRight: string;
    @Input() iconSvgRightHover: string;
    @Input() iconSvgSize: number = 24;

    @Input() isDisabled: boolean;
    @Input() isCollapseButton: boolean = false;
    @Input() isCollapsed: boolean = false;

    @Output() onClick: EventEmitter<any> = new EventEmitter();

    public theme: string;
    public isHovering: boolean = false;

    constructor(@Inject(ThemeService) private themeService: ThemeService) {}

    ngOnInit() {
        this.theme = this.themeService.theme.id;

        this.themeService.themeObs$
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe((theme) => {
                if (theme) {
                    this.theme = this.themeService.theme.id;
                }
            });

        const isAnyIconDefined =
            !!this.iconLeft || !!this.iconSvgLeftHover || !!this.iconRight || !!this.iconRightHover;
        if (!this.btnLabel && isAnyIconDefined) {
            this.btnClass += ' line-height-normal';
        }
    }

    click() {
        this.onClick.emit();
    }

    onMouseEnter() {
        this.isHovering = true;
    }

    onMouseLeave() {
        this.isHovering = false;
    }
}

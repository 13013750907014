import { Component, Inject, Input, OnInit } from '@angular/core';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PlausibleAnalyticsService } from 'src/app/services/plausible-analytics.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { SolarPvTotalityService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-totality.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'cadastreSolaireContactModal',
    templateUrl: './cadastre-solaire-contact-modal.template.html',
})
export class CadastreSolaireContactModalComponent implements OnInit {
    @Input() body = '';

    public htmlContact: any;
    public contactButtonLabel: string = 'Aller plus loin';

    constructor(
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(PlausibleAnalyticsService)
        private plausibleAnalyticsService: PlausibleAnalyticsService,
        @Inject(SolarService) private solarService: SolarService,
        @Inject(SolarPvTotalityService) private solarPvTotalityService: SolarPvTotalityService,
        public activeModal: NgbActiveModal,
    ) {}

    ngOnInit(): void {
        const user = this.localStorageService.get('user');
        this.plausibleAnalyticsService.trackEvent('Projet Solaire - Contact', {
            utilisateur_id: user.id,
        });
        this.onOpen();
    }

    onOpen() {
        const technology = this.solarService.technology;
        const moduleInfo = this.solarService.moduleInfo;

        if (moduleInfo) {
            const customContactButtonLabel = moduleInfo.contacts_bouton;
            this.contactButtonLabel = customContactButtonLabel
                ? customContactButtonLabel
                : 'Aller plus loin';

            let index = 0;
            if (technology == 'pv') {
                const totalPowerPeak = this.solarPvTotalityService.totalPowerPeak || 0;
                const contactPpeaks = moduleInfo.contacts_Pcrete;

                const peaksSorted = contactPpeaks
                    .map((k: string) => parseFloat(k))
                    .sort((a: number, b: number) => a - b);
                const threshold = peaksSorted.find((p: number) => p >= totalPowerPeak);
                index = contactPpeaks.indexOf(threshold);
            }
            this.htmlContact = moduleInfo.contacts[index];
        }
    }

    close() {
        this.activeModal.close();
    }
}

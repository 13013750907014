import {
    Component,
    forwardRef,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit,
    SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'stRange',
    templateUrl: './st-range.template.html',
    styleUrls: ['./st-range.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StRangeComponent),
            multi: true,
        },
    ],
})
export class StRangeComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChild('stRange') input!: ElementRef;

    @Input() label: string;
    @Input() innerId: string;
    @Input() customClass: string;
    @Input() min: number;
    @Input() max: number;
    @Input() step: number;
    @Input() value: number;
    @Input() name: string = '';

    constructor() {}
    onChange: any = () => {};
    onTouch: any = () => {};
    innerModel: number;
    initValue: number;

    ngAfterViewInit(): void {
        this.updateBackground();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            if (changes.value.firstChange) {
                this.initValue = changes.value.currentValue;
            } else {
                this.innerModel = changes.value.currentValue;
                this.updateBackground();
            }
        }
    }

    private updateBackground() {
        const element = this.input.nativeElement;
        const value = this.innerModel !== null ? Number(this.innerModel) : Number(this.initValue);
        const min = Number(element.min);
        const max = Number(element.max);
        const progress = ((value - min) / (max - min)) * 100;

        element.style.background = `linear-gradient(to right, var(--primary) ${progress}%, #DEE2E6 ${progress}%)`;
    }

    set val(val: number) {
        if (val !== undefined && this.innerModel !== val) {
            this.innerModel = val;
            this.onChange(val);
            this.onTouch(val);
        }
    }

    writeValue(value: number) {
        this.val = value;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}

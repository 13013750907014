import { Component, Input, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
    selector: 'info-contact',
    templateUrl: './contact-modal.template.html',
})
export class ContactModalComponent implements OnInit {
    @Input() subject: string = 'Envoyer un commentaire';

    constructor(
        public activeModal: NgbActiveModal,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
    ) {}

    public contactMail: string;
    public href: string;

    ngOnInit(): void {
        const group = this.localStorageService.get('group');
        this.contactMail = group.contact_mail ? group.contact_mail : 'contact@energies-demain.com';

        this.href = this.contactMail;
        if (this.subject) {
            this.href += `?Subject=${this.subject}`;
        }
    }
}

import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular-ivy';

import { FileSaverModule } from 'ngx-filesaver';
import { NgPipesModule } from 'ngx-pipes';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { ToastrModule } from 'ngx-toastr';

import {
    NgbActiveModal,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbModule,
    NgbNavModule,
    NgbPopoverModule,
    NgbTooltipConfig,
} from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './interceptor';
import { SharedModule } from './components/shared.module';

// services
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/AuthService';
import { AtacService } from './services/AtacService';
import { CatalogService } from './services/catalog.service';
import { ClearService } from './services/ClearService';
import { ColorService } from './services/ColorService';
import { DataService } from './services/DataService';
import { DownloadService } from './services/DownloadService';
import { EventService } from './services/event.service';
import { FilesService } from './services/FilesService';
import { FilterDataService } from './services/FilterDataService';

import { ImportedDataService } from './services/ImportedDataService';
import { LegendService } from './services/legend.service';
import { LocalStorageService } from './services/local-storage.service';
import { MapService } from './services/map.service';
import { MetaTagService } from './services/MetaTagService';
import { ModuleService } from './services/module.service';
import { PaginationService } from './services/pagination.service';
import { PdfExportService } from './services/pdf-export.service';
import { PlausibleAnalyticsService } from './services/plausible-analytics.service';
import { RestService } from './services/RestService';
import { ScenariosService } from './services/ScenariosService';
import { ShapeFileService } from './services/ShapeFileService';
import { StatsService } from './services/StatsService';
import { TerService } from './services/TerService';
import { ThemeService } from './services/ThemeService';
import { TranslationService } from './services/translation.service';
import { SearchService } from './services/search.service';
import { UsefulService } from './services/UsefulService';

import { LinearDistribution } from './services/plotIndicator/distributions/methods/Linear';
import { ManualDistribution } from './services/plotIndicator/distributions/methods/Manual';
import { MeanDistribution } from './services/plotIndicator/distributions/methods/Mean';
import { NaturalBreaksDistribution } from './services/plotIndicator/distributions/methods/NaturalBreaks';
import { QuantileDistribution } from './services/plotIndicator/distributions/methods/Quantile';
import { StandardDeviationDistribution } from './services/plotIndicator/distributions/methods/StandardDeviation';
import { DistributionClassService } from './services/plotIndicator/distributions/DistributionClassService';
import { DistributionValueService } from './services/plotIndicator/distributions/DistributionValueService';
import { PlotIndicatorService } from './services/plotIndicator/plot-indicator.service';
import { PlotProsperReseauxIndicatorService } from './services/plotIndicator/plot-prosper-reseaux.service';
import { PlotCadastreSolaireIndicatorService } from './services/plotIndicator/plot-cadastre-solaire.service';
import { PlotCadastreSolaireIndicatorRoofSectionService } from './services/plotIndicator/plot-cadastre-solaire-roof-section.service';
import { PlotTerritoryIndicatorService } from './services/plotIndicator/plot-territory.service';
import { PlotCustomTerritoryIndicatorService } from './services/plotIndicator/plot-custom-territory.service';

import { ProsperReseauxService } from './services/prosper-reseaux/prosper-reseaux.service';
import { ProsperReseauxClearService } from './services/prosper-reseaux/prosper-reseaux-clear.service';
import { ProsperReseauxApplyChangesService } from './services/prosper-reseaux/prosper-reseaux-apply-changes.service';
import { ProsperReseauxElementService } from './services/prosper-reseaux/prosper-reseaux-element.service';
import { ProsperReseauxProsperActionsScenarioService } from './services/prosper-reseaux/prosper-reseaux-prosper-actions-scenario.service';

import { SolarService } from './services/cadastre-solaire/cadastre-solaire.service';
import { SolarPvSurplusService } from './services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { SolarPvTotalityService } from './services/cadastre-solaire/cadastre-solaire-pv-totality.service';
import { SolarThermalService } from './services/cadastre-solaire/cadastre-solaire-thermal.service';

// controllers
import { LoginComponent } from './controllers/login/login.controller';
import { SydeslTutorialModalComponent } from './controllers/login/cadastre-sydesl/tutorial/sydesl-tutorial.component';
import { ForgotPasswordModal } from './controllers/login/modals/forgot-password/forgot-password.component';
import { ResetPasswordModal } from './controllers/login/modals/reset-password/reset-password.component';

import { MainComponent } from './controllers/main/main.controller';
import { TerritorySelectionModalComponent } from './controllers/main/modals/territory-selection/territory-selection-modal.component';

import { IndicatorCatalogComponent } from './controllers/main/components/module-indicator-catalog/indicator-catalog.component';
import { DownloadComponent } from './controllers/main/components/module-download/download.component';
// import { ElectricLoadCurveModalComponent } from './controllers/main/modals/electric-load-curve/electric-load-curve-modal.component';
import { ImportModalComponent } from './controllers/main/modals/import-file/import-modal.component';
import { MapBoundariesComponent } from './controllers/main/components/module-map-boundaries/map-boundaries.component';

import { MapLegendComponent } from './controllers/main/components/module-map-legend/map-legend.component';
import { MapAttributionsComponent } from './controllers/main/components/module-map-attributions/map-attributions.component';
import { LegendToolbarComponent } from './controllers/main/components/module-map-legend/components/legend-toolbar.component';
import { LegendModalComponent } from './controllers/main/components/module-map-legend/modals/legend-modal.component';

import { IndicatorParametersComponent } from './controllers/main/components/module-indicator-parameters/indicator-parameters.component';
import { StInfoComponent } from './controllers/main/components/module-info/info.component';
import { ContactModalComponent } from './controllers/main/components/module-info/modals/contact-modal.component';

import { SearchComponent } from './controllers/main/components/module-search-bar/search-bar.component';
import { LoadTerritoriesComponent } from './controllers/main/components/module-load-territories/load-territories.component';

import { DashboardControlComponent } from './controllers/main/components/module-dashboard/dashboard-control.component';
import { DashboardTabComponent } from './controllers/main/components/module-dashboard/components/dashboard-tab.component';
import { DashboardPlotComponent } from './controllers/main/components/module-dashboard/components/dashboard-plot/dashboard-plot.component';
import { DashboardTableComponent } from './controllers/main/components/module-dashboard/components/dashboard-table/dashboard-table.component';
import { DashboardTerritoryTableComponent } from './controllers/main/components/module-dashboard/components/dashboard-table/components/territory-table/territory-table.component';
import { DashboardBuildingCustomTableComponent } from './controllers/main/components/module-dashboard/components/dashboard-table/components/building-custom-table/building-custom-table.component';
import { DashboardService } from './services/dashboard.service';

import { AtacResultsModal } from './controllers/main/components/module-atac/modals/atac-results.component';
import { AtacComponent } from './controllers/main/components/module-atac/atac.component';
import { AtacListComponent } from './controllers/main/components/module-atac/components/atac-list.component';
import { AtacImportComponent } from './controllers/main/components/module-atac/components/atac-import.component';
import { AtacParameterComponent } from './controllers/main/components/module-atac/components/atac-parameter.component';
import { SingleIputComponent } from './controllers/main/components/module-atac/components/single-input/single-input.component';
import { ImportLoadCurveModal } from './controllers/main/components/module-atac/modals/import-load-curve.component';

import { IndicatorFilterComponent } from './controllers/main/components/module-indicator-filter/indicator-filter.component';
import { SingleFilterComponent } from './controllers/main/components/module-indicator-filter/components/single-filter.component';

import { ProsperReseauxComponent } from './controllers/main/components/module-prosper-reseaux/prosper-reseaux.component';
import { ProsperReseauxNewElementModal } from './controllers/main/components/module-prosper-reseaux/modals/prosper-reseaux-new-element.component';
import { ProsperActionsScenario } from './controllers/main/components/module-prosper-reseaux/components/prosper-actions-scenario/prosper-actions-scenario.component';
import { ProsperReseauxSearchElementComponent } from './controllers/main/components/module-prosper-reseaux/components/search-element/search-element.component';
import { ConfirmationPRModalComponent } from './controllers/main/components/module-prosper-reseaux/modals/confirmation-modal.component';

import { CadastreSolaireComponent } from './controllers/main/components/module-cadastre-solaire/cadastre-solaire.component';
import { CadastreSolaireCtaMobileComponent } from './controllers/main/components/module-cadastre-solaire/components/cta-mobile/cadastre-solaire-cta-mobile.component';
import { CadastreSolaireResultCardComponent } from './controllers/main/components/module-cadastre-solaire/components/result-card/cadastre-solaire-result-card.component';
import { CadastreSolaireInitComponent } from './controllers/main/components/module-cadastre-solaire/components/init/cadastre-solaire-init.component';
import { CadastreSolaireTechnologyComponent } from './controllers/main/components/module-cadastre-solaire/components/technology/cadastre-solaire-technology.component';
import { CadastreSolaireParametersComponent } from './controllers/main/components/module-cadastre-solaire/components/parameters/cadastre-solaire-parameters.component';

import { CadastreSolairePvComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/cadastre-solaire-pv.component';
import { CadastreSolairePvValorizationComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/components/valorization/cadastre-solaire-pv-valorization.component';
import { CadastreSolairePvTotalityComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/components/totality/cadastre-solaire-pv-totality.component';
import { CadastreSolairePvSurplusComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/components/surplus/cadastre-solaire-pv-surplus.component';
import { CadastreSolairePvSurplusEquipmentsComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/components/surplus/components/equipments/cadastre-solaire-pv-surplus-equipments.component';
import { CadastreSolairePvSurplusImportComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/components/surplus/components/import/cadastre-solaire-pv-surplus-import.component';
import { CadastreSolairePvSurplusConsumptionComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/components/surplus/components/consumption/cadastre-solaire-pv-surplus-consumption.component';
import { CadastreSolairePvSurplusSimulationComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/components/surplus/components/simulation/cadastre-solaire-pv-surplus-simulation.component';
import { CadastreSolairePvSurplusPlotComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/components/surplus/components/plot/cadastre-solaire-pv-surplus-plot.component';
import { CadastreSolairePvSurplusTypicalDayComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/components/surplus/components/plot/components/typical-day/cadastre-solaire-pv-surplus-typical-day.component';
import { CadastreSolairePvSurplusMonthlyComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/components/surplus/components/plot/components/monthly/cadastre-solaire-pv-surplus-monthly.component';
import { CadastreSolairePvSurplusElectricVehiculeComponent } from './controllers/main/components/module-cadastre-solaire/components/pv/components/surplus/components/electric-vehicule/cadastre-solaire-pv-surplus-electric-vehicule.component';

import { CadastreSolaireResultsComponent } from './controllers/main/components/module-cadastre-solaire/components/results/cadastre-solaire-results.component';
import { CadastreSolaireThermalComponent } from './controllers/main/components/module-cadastre-solaire/components/thermal/cadastre-solaire-thermal.component';
import { CadastreSolaireNewRoofComponent } from './controllers/main/components/module-cadastre-solaire/components/new-roof/cadastre-solaire-new-roof.component';
import { CadastreSolaireContactModalComponent } from './controllers/main/components/module-cadastre-solaire/modals/contact/cadastre-solaire-contact-modal.component';
import { CadastreSolaireInfoModalComponent } from './controllers/main/components/module-cadastre-solaire/modals/info/cadastre-solaire-info-modal.component';

import { ExternalLoginComponent } from './controllers/external-login/external-login.component';

import { AddUserComponent } from './controllers/admin/user/user-add.controller';
import { UserManageComponent } from './controllers/admin/user/user-manage.controller';
import { UpdateUserComponent } from './controllers/admin/user/user-update.controller';

import { GroupAddComponent } from './controllers/admin/group/group-add.controller';
import { ManageGroupComponent } from './controllers/admin/group/group-manage.controller';
import { UpdateGroupComponent } from './controllers/admin/group/group-update.controller';

import { BaseManageComponent } from './controllers/admin/base/base-manage.controller';

import { AddFilterComponent } from './controllers/admin/filter/filter-add.controller';
import { UpdateFilterComponent } from './controllers/admin/filter/filter-update.controller';
import { ManageFilterComponent } from './controllers/admin/filter/filter-manage.controller';

import { AddIndicatorComponent } from './controllers/admin/indicator/indicator-add.controller';
import { UpdateIndicatorComponent } from './controllers/admin/indicator/indicator-update.controller';
import { ManageIndicatorsComponent } from './controllers/admin/indicator/indicator-manage.controller';

import { NgbdSortableHeader } from './components/st-table/sortable.directive';
import { StTableService } from './components/st-table/st-table.service';
import { ScenariosModalComponent } from './controllers/main/modals/scenarios/scenarios-modal.component';
import { Router } from '@angular/router';

// Import Angular's locale data
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

// Register the locale data for French
registerLocaleData(localeFr);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        SydeslTutorialModalComponent,
        IndicatorCatalogComponent,
        SingleIputComponent,
        ForgotPasswordModal,
        ResetPasswordModal,
        ImportLoadCurveModal,
        AtacResultsModal,
        AtacListComponent,
        AtacImportComponent,
        AtacParameterComponent,
        AtacComponent,
        SingleFilterComponent,
        IndicatorFilterComponent,
        MapBoundariesComponent,
        ProsperReseauxComponent,
        ProsperReseauxNewElementModal,
        ProsperActionsScenario,
        ProsperReseauxSearchElementComponent,
        DashboardTabComponent,
        DashboardPlotComponent,
        DashboardTableComponent,
        DashboardTerritoryTableComponent,
        DashboardBuildingCustomTableComponent,
        IndicatorParametersComponent,
        DownloadComponent,
        MapLegendComponent,
        MapAttributionsComponent,
        LegendToolbarComponent,
        LegendModalComponent,
        UserManageComponent,
        AddUserComponent,
        UpdateUserComponent,
        ManageGroupComponent,
        BaseManageComponent,
        AddFilterComponent,
        UpdateFilterComponent,
        ManageFilterComponent,
        AddIndicatorComponent,
        UpdateIndicatorComponent,
        ManageIndicatorsComponent,
        GroupAddComponent,
        UpdateGroupComponent,
        // ElectricLoadCurveModalComponent,
        CadastreSolaireContactModalComponent,
        CadastreSolaireCtaMobileComponent,
        CadastreSolaireResultCardComponent,
        CadastreSolaireInitComponent,
        CadastreSolaireTechnologyComponent,
        CadastreSolaireParametersComponent,
        CadastreSolairePvComponent,
        CadastreSolairePvValorizationComponent,
        CadastreSolairePvTotalityComponent,
        CadastreSolairePvSurplusComponent,
        CadastreSolairePvSurplusEquipmentsComponent,
        CadastreSolairePvSurplusImportComponent,
        CadastreSolairePvSurplusConsumptionComponent,
        CadastreSolairePvSurplusSimulationComponent,
        CadastreSolairePvSurplusPlotComponent,
        CadastreSolairePvSurplusTypicalDayComponent,
        CadastreSolairePvSurplusMonthlyComponent,
        CadastreSolairePvSurplusElectricVehiculeComponent,
        CadastreSolaireThermalComponent,
        CadastreSolaireNewRoofComponent,
        CadastreSolaireResultsComponent,
        CadastreSolaireComponent,
        ImportModalComponent,
        MainComponent,
        ConfirmationPRModalComponent,
        SearchComponent,
        LoadTerritoriesComponent,
        ExternalLoginComponent,
        DashboardControlComponent,
        StInfoComponent,
        ContactModalComponent,
        TerritorySelectionModalComponent,
        CadastreSolaireInfoModalComponent,
        NgbdSortableHeader,
        ScenariosModalComponent,
    ],
    imports: [
        FileSaverModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgPipesModule,
        NgbCollapseModule,
        NgbDropdownModule,
        NgxSmartModalModule.forRoot(),
        SharedModule,
        NgbNavModule,
        NgbPopoverModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            enableHtml: true,
        }),
    ],
    providers: [
        UsefulService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: resourceProviderFactory,
            deps: [MetaTagService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: Window, useValue: window },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        NgbTooltipConfig,
        RestService,
        LegendService,
        LocalStorageService,
        ModuleService,
        AtacService,
        ColorService,
        FilesService,
        ImportedDataService,
        StatsService,
        EventService,
        MapService,
        TerService,
        DataService,
        FilterDataService,
        ProsperReseauxService,
        ProsperReseauxClearService,
        ProsperReseauxApplyChangesService,
        ProsperReseauxElementService,
        ProsperReseauxProsperActionsScenarioService,
        ShapeFileService,
        ThemeService,
        DistributionClassService,
        LinearDistribution,
        ManualDistribution,
        MeanDistribution,
        NaturalBreaksDistribution,
        QuantileDistribution,
        StandardDeviationDistribution,
        DistributionValueService,
        PlotCadastreSolaireIndicatorService,
        PlotCadastreSolaireIndicatorRoofSectionService,
        PlotProsperReseauxIndicatorService,
        PlotTerritoryIndicatorService,
        PlotCustomTerritoryIndicatorService,
        PlotIndicatorService,
        ScenariosService,
        DownloadService,
        AuthService,
        CatalogService,
        ClearService,
        MetaTagService,
        PdfExportService,
        PlausibleAnalyticsService,
        PaginationService,
        SolarPvTotalityService,
        SolarService,
        SolarThermalService,
        SolarPvSurplusService,
        AuthGuardService,
        NgbActiveModal,
        SearchService,
        TranslationService,
        DashboardService,
        DecimalPipe,
        StTableService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function resourceProviderFactory(provider: MetaTagService) {
    return () => provider.ngOnInit();
}

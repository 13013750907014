import {
    Component,
    Inject,
    OnInit,
    Input,
    Renderer2,
    ViewEncapsulation,
    OnDestroy,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as L from 'leaflet';

import { ContactModalComponent } from '../module-info/modals/contact-modal.component';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MapService } from 'src/app/services/map.service';

@UntilDestroy()
@Component({
    selector: 'map-attributions',
    template: '',
    styleUrls: ['./map-attributions.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MapAttributionsComponent implements OnInit, OnDestroy {
    @Input() position: L.ControlPosition = 'bottomright';

    public attributionControl: L.Control.Attribution;

    private attributions = [
        {
            name: 'raster',
            text: '',
            order: 1,
        },
        {
            name: 'environment',
            text: '',
            order: 2,
        },
        { name: 'contact', text: '', order: 3 },
    ];
    private currentAttributionText: string;

    private contactMail: string;
    private unlistenClickOnContact: () => void;

    constructor(
        private modalService: NgbModal,
        private renderer: Renderer2,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(MapService) private mapService: MapService,
    ) {}

    ngOnInit(): void {
        const environmentAttribution = this._getAttribution('environment');
        environmentAttribution.text = `<a href="${environment.linkMap}" target="_blank">${environment.labelMap}</a>`;

        const group = this.localStorageService.get('group');
        this.contactMail = group.contact_mail;
        if (this.contactMail) {
            const contactAttribution = this._getAttribution('contact');
            contactAttribution.text = `<a id="contact-mail">Envoyer des commentaires sur l’outil</a>`;
        }

        this.mapService.map$.pipe(untilDestroyed(this)).subscribe((map) => {
            if (map) {
                this._initControl();
            }
        });

        this.mapService.rasterObs$.pipe(untilDestroyed(this)).subscribe((raster) => {
            if (raster) {
                this._updateRasterAttribution(raster.attribution);
            }
        });
    }

    ngOnDestroy() {
        if (this.contactMail) {
            this.unlistenClickOnContact();
        }
    }

    private _getAttribution(name: string) {
        return this.attributions.find((attribution) => attribution.name == name);
    }

    private _concatAttributionsText() {
        return this.attributions
            .filter((attribution) => !!attribution.text)
            .map((attribution) => attribution.text)
            .sort((a: any, b: any) => b.order - a.order)
            .join(' | ');
    }

    private _initControl() {
        this.attributionControl = L.control
            .attribution({ position: 'bottomright' })
            .addTo(this.mapService.map);

        this.currentAttributionText = this._concatAttributionsText();
        this.attributionControl.addAttribution(this.currentAttributionText);

        if (this.contactMail) {
            this._handleClickOnContactAttribution();
        }
    }

    private _updateRasterAttribution(newRasterAttribution: string) {
        this.attributionControl.removeAttribution(this.currentAttributionText);

        const rasterAttribution = this._getAttribution('raster');
        rasterAttribution.text = newRasterAttribution;
        this.currentAttributionText = this._concatAttributionsText();
        this.attributionControl.addAttribution(this.currentAttributionText);

        if (this.contactMail) {
            this._handleClickOnContactAttribution();
        }
    }

    private _handleClickOnContactAttribution() {
        const contactMailElement = document.getElementById('contact-mail');
        if (contactMailElement) {
            if (this.unlistenClickOnContact) {
                this.unlistenClickOnContact();
            }

            this.unlistenClickOnContact = this.renderer.listen(
                contactMailElement,
                'click',
                (event) => {
                    this.modalService.open(ContactModalComponent);
                },
            );
        }
    }
}

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'stCheckbox',
    templateUrl: './st-checkbox.template.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StCheckboxComponent),
            multi: true,
        },
    ],
})
export class StCheckboxComponent implements ControlValueAccessor {
    @Input() isTransparent: boolean;
    @Input() isDisabled: boolean;
    @Input() trueValue: any = true;
    @Input() falseValue: any = false;
    @Input() containerClass: string;
    @Input() inputClass: string;
    @Input() labelClass: string;
    @Input() innerId: string;

    constructor() {}
    onChange: any = () => {};
    onTouch: any = () => {};
    val = false;

    set value(val) {
        if (val !== undefined && this.val !== val) {
            this.val = val;
            val = this.val ? this.trueValue : this.falseValue;
            this.onChange(val);
            this.onTouch(val);
        }
    }

    writeValue(value: any) {
        this.value = value === this.trueValue;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}

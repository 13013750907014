import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'confirmationModal',
    templateUrl: './confirmation-modal.template.html',
})
export class ConfirmationModalComponent {
    @Input() header = 'Attention !';
    @Input() body = '';
    @Input() validateLabel = 'Valider';
    @Input() cancelLabel = 'Annuler';

    constructor(public activeModal: NgbActiveModal) {}

    validate() {
        this.activeModal.close('confirm');
    }

    close() {
        this.activeModal.dismiss('cancel');
    }
}

<div id="solarInfoModal" tabindex="-1" aria-labelledby="solarInfoModal" aria-modal="true">
    <div class="modal-header">
        <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
        <div [innerHtml]="body"></div>
    </div>
    <div class="modal-footer">
        <stButton btnLabel="Fermer" btnClass="btn-st-default" (click)="close()"></stButton>
    </div>
</div>

<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center solar-card">
                        <div class="col-auto px-0 text-center">
                            <span class="fa-stack">
                                <i class="fa fa-circle-thin fa-stack-2x"></i>
                                <i class="fa fa-info fa-stack-1x"></i>
                            </span>
                        </div>
                        <div class="col">
                            <p>
                                Pour un projet photovoltaïque en vente totale, vous êtes libre de
                                choisir la taille de votre installation. Les coûts d'installation et
                                le tarif d'achat de l'énergie sont dégressifs selon la puissance
                                installée.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <div class="my-2 solar-loader-icon" [hidden]="!showLoader">
                    <div>
                        <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
                    </div>
                    <div>
                        Simulation de votre <br />
                        projet en cours
                    </div>
                </div>

                <div [hidden]="showLoader">
                    <div class="container-fluid position-relative solar-card mb-3" *ngIf="slider">
                        <h5 class="text-st-primary mb-3">{{ adjustInstallationSizeLabel }}</h5>

                        <stRange
                            customClass="mb-3"
                            innerId="pv-module-count"
                            [min]="1"
                            [max]="slider.maxModuleCount"
                            [step]="1"
                            [value]="slider.moduleCount"
                            [(ngModel)]="slider.moduleCount"
                            (change)="updateModuleCountForSell()"
                        >
                        </stRange>

                        <div class="row mb-2">
                            <div class="col-4 panel-count">
                                {{ slider.moduleCount }} {{ slider.moduleCount == 1 ? 'panneau' :
                                'panneaux' }}
                            </div>
                            <div class="col-4 text-center panel-area">
                                {{ slider.moduleArea }}&nbsp;m²
                            </div>
                            <div class="col-4 d-flex justify-content-end peak-power">
                                {{ slider.stringifiedPeakPower }}&nbsp;kWc
                            </div>
                        </div>

                        <div class="possible-installation" *ngIf="slider.maxPeakPower >= 3">
                            <i>
                                <small>
                                    <span class="text-st-primary">
                                        Sélectionner une puissance selon les seuils de tarif de
                                        vente&nbsp;:
                                    </span>
                                    <ul>
                                        <li *ngIf="slider.maxPeakPower >= 3">
                                            <a
                                                _target="_blank"
                                                class="text-st-active"
                                                (click)="clickOnPossibleInstallation(3)"
                                            >
                                                3&nbsp;kWc
                                            </a>
                                        </li>
                                        <li *ngIf="slider.maxPeakPower >= 9">
                                            <span>,</span>
                                            <a
                                                _target="_blank"
                                                class="text-st-active"
                                                (click)="clickOnPossibleInstallation(9)"
                                            >
                                                9&nbsp;kWc
                                            </a>
                                        </li>
                                        <li *ngIf="slider.maxPeakPower >= 36">
                                            <span>,</span>
                                            <a
                                                _target="_blank"
                                                class="text-st-active"
                                                (click)="clickOnPossibleInstallation(36)"
                                            >
                                                36&nbsp;kWc
                                            </a>
                                        </li>
                                    </ul>
                                    <span>.</span>
                                </small>
                            </i>
                        </div>

                        <div [(ngbCollapse)]="!activeCollapseWarningPower">
                            <div class="container-fluid px-0 pt-2">
                                <hr class="m-0" />
                                <div class="row d-flex align-items-center pt-3">
                                    <div class="col-1 text-center">
                                        <i
                                            class="bi bi-exclamation-triangle fa-2x text-st-active"
                                        ></i>
                                    </div>
                                    <div class="col">
                                        <div [innerHtml]="htmlPvWarningSubcribedPower"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <cadastre-solaire-result-card
                        #resultInvestment
                        customClass="mb-3"
                        iconSvg="list_check"
                        [titleLabel]="titleInvestment"
                        [contentHtml]="htmlPvInvestment"
                        [modalContentHtml]="tooltipPvInvestmentDetail"
                        [connectionCost]="connectionCost"
                    ></cadastre-solaire-result-card>

                    <cadastre-solaire-result-card
                        customClass="mb-3"
                        iconSvg="bolt"
                        [titleLabel]="titleProduction"
                        [contentHtml]="htmlPvProduction"
                        [modalContentHtml]="tooltipPvProductionDetail"
                    ></cadastre-solaire-result-card>

                    <cadastre-solaire-result-card
                        icon="fa fa-eur fs-3"
                        [titleLabel]="titleEconomy"
                        [contentHtml]="htmlPvEconomy"
                        [modalContentHtml]="tooltipPvSellingDetail"
                    ></cadastre-solaire-result-card>

                    <ng-container *ngIf="!modularity.simulations">
                        <ng-container *ngIf="!!htmlPvWarning">
                            <div class="mt-3" [innerHtml]="htmlPvWarning"></div>
                        </ng-container>
                        <ng-container *ngIf="!htmlPvWarning">
                            <div class="text-center mt-3">
                                <small>
                                    Ces données sont issues d'un traitement automatisé et sont un
                                    premier ordre d'idée. Cela ne remplace pas la visite d'un
                                    technicien agréé.
                                </small>
                            </div>
                        </ng-container>
                    </ng-container>

                    <div class="mt-3" *ngIf="modularity.downloadResults && !modularity.simulations">
                        <button
                            type="button"
                            class="btn btn-st-primary solar-pdf"
                            (click)="downloadPdf()"
                            [disabled]="isResultsDownloading"
                        >
                            <span *ngIf="!isResultsDownloading">Télécharger le récapitulatif</span>
                            <i class="fa fa-spinner fa-pulse" *ngIf="isResultsDownloading"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer border-top" [hidden]="showLoader">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-5">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-default w-100 h-100"
                        btnLabel="Retour"
                        (click)="cancel()"
                    >
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        btnLabel="Valider ma simulation"
                        (click)="validate()"
                        *ngIf="modularity.simulations"
                    >
                    </stButton>
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        [btnLabel]="contactButtonLabel"
                        (click)="openContactModal()"
                        *ngIf="!modularity.simulations"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>

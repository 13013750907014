<input
    #stRange
    type="range"
    class="custom-range {{ customClass }}"
    [id]="innerId"
    [(ngModel)]="innerModel"
    (ngModelChange)="onChange($event)"
    [min]="min"
    [max]="max"
    [value]="value"
    [step]="step"
/>

<ng-template #emojiSmile>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-emoji-smile-fill"
        viewBox="0 0 16 16"
    >
        <path
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8"
        />
    </svg>
</ng-template>

<ng-template #emojiNeutral>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-emoji-neutral-fill"
        viewBox="0 0 16 16"
    >
        <path
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-3 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8"
        />
    </svg>
</ng-template>

<ng-template #emojiFrown>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-emoji-frown-fill"
        viewBox="0 0 16 16"
    >
        <path
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-2.715 5.933a.5.5 0 0 1-.183-.683A4.5 4.5 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.5 3.5 0 0 0 8 10.5a3.5 3.5 0 0 0-3.032 1.75.5.5 0 0 1-.683.183M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8"
        />
    </svg>
</ng-template>

<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center solar-card">
                        <div class="col-auto px-0 text-center">
                            <span class="fa-stack">
                                <i class="fa fa-circle-thin fa-stack-2x"></i>
                                <i class="fa fa-info fa-stack-1x"></i>
                            </span>
                        </div>
                        <div class="col">
                            <p>
                                Pour optimiser l'usage de votre électricité solaire, rechargez votre
                                véhicule électrique pendant la journée. En rechargeant votre
                                véhicule quand le soleil brille, vous utilisez directement
                                l'électricité générée par vos panneaux. Vous minimisez ainsi l'achat
                                d'énergie sur le réseau et votre empreinte carbone.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <div class="container-fluid solar-card mb-3">
                    <h5 class="text-st-primary mb-3">
                        Je décris mes usages de mobilité électrique
                    </h5>

                    <form [formGroup]="mobiliyUsesFormGroup">
                        <div class="row d-flex align-items-center mb-2">
                            <label
                                for="electric-vehicule-count"
                                class="col-sm control-label text-start fw-normal py-0"
                            >
                                Nombre de voiture électrique
                            </label>
                            <div class="col-sm-5">
                                <div class="row d-flex align-items-center">
                                    <div class="col">
                                        <stSelect
                                            formControlName="electricVehiculeCount"
                                            innerId="electric-vehicule-count"
                                            [data]="electricVehiculeCountOptions"
                                            labelField="label"
                                            valueField="value"
                                            type="primary"
                                        >
                                        </stSelect>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row d-flex align-items-center">
                            <div class="col-sm-7">
                                <div class="row d-flex align-items-center">
                                    <label class="col" for="annual-distance"
                                        >Kilomètres annuels cumulés de vos véhicules</label
                                    >
                                    <div class="col-auto px-0 text-center">
                                        <stButton
                                            class="btn-info-modal"
                                            btnClass="d-flex align-items-center py-0"
                                            iconLeft="bi bi-info-circle-fill text-st-secondary fs-5"
                                            iconLeftHover="bi bi-info-circle fs-5"
                                            (onClick)="openAnnualDistanceModal()"
                                            title="Plus d'information"
                                        ></stButton>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-5">
                                <div class="row d-flex align-items-center">
                                    <div class="col-10">
                                        <stInput
                                            formControlName="annualDistance"
                                            innerId="annual-distance"
                                            class="primary"
                                            type="number"
                                            [min]="1"
                                        >
                                        </stInput>
                                    </div>
                                    <div class="col-2">km</div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="container-fluid solar-card mb-3">
                    <h5 class="text-st-primary mb-3">Je choisis mon horaire de recharge</h5>

                    <div class="st-pills">
                        <ul
                            ngbNav
                            #navRechargingTime="ngbNav"
                            [activeId]="chargingScheduleActiveTabId"
                            class="nav-tabs"
                        >
                            <li *ngFor="let info of chargingScheduleInfo" [ngbNavItem]="info.id">
                                <a
                                    ngbNavLink
                                    (click)="setChargingScheduleTabId(info.id)"
                                    [ngClass]="{'not-activate-by-user': chargingScheduleActiveTabId == null}"
                                >
                                    {{ info.label }}
                                </a>
                                <ng-template ngbNavContent>
                                    <div
                                        class="alert d-flex align-items-center"
                                        [ngClass]="info.class"
                                        role="alert"
                                    >
                                        <ng-container *ngTemplateOutlet="info.emoji"></ng-container>
                                        <div class="description">{{ info.description }}</div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                    </div>

                    <div class="st-pills-body" *ngIf="chargingScheduleActiveTabId != null">
                        <div class="container-fluid mb-3" *ngIf="!showMobileUi">
                            <div class="row">
                                <div class="col d-flex align-items-center justify-content-center">
                                    <img
                                        src="./assets/img/cadastre-solaire/electric_vehicule_charging_schedule_day.svg"
                                        alt="electric-vehicule-charging-schedule-day"
                                        [ngClass]="{'selected': chargingScheduleActiveTabId == 1}"
                                    />
                                </div>
                                <div class="col d-flex align-items-center justify-content-center">
                                    <img
                                        src="./assets/img/cadastre-solaire/electric_vehicule_charging_schedule_evening.svg"
                                        alt="electric-vehicule-charging-schedule-evening"
                                        [ngClass]="{'selected': chargingScheduleActiveTabId == 2}"
                                    />
                                </div>
                                <div class="col d-flex align-items-center justify-content-center">
                                    <img
                                        src="./assets/img/cadastre-solaire/electric_vehicule_charging_schedule_night.svg"
                                        alt="electric-vehicule-charging-schedule-night"
                                        [ngClass]="{'selected': chargingScheduleActiveTabId == 3}"
                                    />
                                </div>
                            </div>
                        </div>

                        <div [ngbNavOutlet]="navRechargingTime"></div>
                    </div>
                </div>

                <div class="container-fluid solar-card mb-3">
                    <h5 class="text-st-primary mb-3">Je choisis ma puissance de recharge</h5>

                    <div class="st-pills">
                        <ul
                            ngbNav
                            #navRechargingPower="ngbNav"
                            [activeId]="chargingPointActiveTabId"
                        >
                            <li *ngFor="let info of chargingPointInfo" [ngbNavItem]="info.id">
                                <a
                                    ngbNavLink
                                    (click)="setChargingPointTabId(info.id)"
                                    [ngClass]="{'not-activate-by-user': chargingPointActiveTabId == null}"
                                >
                                    {{ info.label }}
                                </a>
                                <ng-template ngbNavContent>
                                    <div
                                        class="alert d-flex align-items-center"
                                        [ngClass]="info.class"
                                        role="alert"
                                    >
                                        <ng-container *ngTemplateOutlet="info.emoji"></ng-container>
                                        <div class="description">{{ info.description }}</div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                    </div>

                    <div class="st-pills-body" *ngIf="chargingPointActiveTabId != null">
                        <div class="container-fluid mb-3" *ngIf="!showMobileUi">
                            <div class="row d-flex align-items-end">
                                <div class="col d-flex align-items-center justify-content-center">
                                    <img
                                        src="./assets/img/cadastre-solaire/electric_vehicule_charging_point_reinforced_socket.svg"
                                        alt="electric-vehicule-charging-point-reinforced-socket"
                                        [ngClass]="{'selected': chargingPointActiveTabId == 1}"
                                    />
                                </div>
                                <div class="col d-flex align-items-center justify-content-center">
                                    <img
                                        src="./assets/img/cadastre-solaire/electric_vehicule_charging_point_standard.svg"
                                        alt="electric-vehicule-charging-point-standard"
                                        [ngClass]="{'selected': chargingPointActiveTabId == 2}"
                                    />
                                </div>
                                <div class="col d-flex align-items-center justify-content-center">
                                    <img
                                        src="./assets/img/cadastre-solaire/electric_vehicule_charging_point_fast.svg"
                                        alt="electric-vehicule-charging-power-fast"
                                        [ngClass]="{'selected': chargingPointActiveTabId == 3}"
                                    />
                                </div>
                            </div>
                        </div>

                        <div [ngbNavOutlet]="navRechargingPower"></div>
                    </div>
                </div>

                <div class="container-fluid solar-card">
                    <h5 class="text-st-primary mb-3">Je choisis ma fréquence de recharge</h5>

                    <div class="st-pills">
                        <ul
                            ngbNav
                            #navRechargingFrequency="ngbNav"
                            [activeId]="chargingFrequencyActiveTabId"
                        >
                            <li *ngFor="let info of chargingFrequencyInfo" [ngbNavItem]="info.id">
                                <a
                                    ngbNavLink
                                    (click)="setChargingFrequencyTabId(info.id)"
                                    [ngClass]="{'not-activate-by-user': chargingFrequencyActiveTabId == null}"
                                >
                                    {{ info.label }}
                                </a>
                                <ng-template ngbNavContent>
                                    <div
                                        class="alert d-flex align-items-center"
                                        [ngClass]="info.class"
                                        role="alert"
                                    >
                                        <ng-container *ngTemplateOutlet="info.emoji"></ng-container>
                                        <div class="description">{{ info.description }}</div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                    </div>

                    <div class="st-pills-body" *ngIf="chargingFrequencyActiveTabId != null">
                        <div class="mb-3" *ngIf="!showMobileUi">
                            <img
                                *ngIf="chargingFrequencyActiveTabId == 1"
                                src="./assets/img/cadastre-solaire/electric_vehicule_charging_frequency_five.svg"
                                alt="electric-vehicule-charging-frequency-five"
                                width="100%"
                                class="selected"
                            />
                            <img
                                *ngIf="chargingFrequencyActiveTabId == 2"
                                src="./assets/img/cadastre-solaire/electric_vehicule_charging_frequency_three.svg"
                                alt="electric-vehicule-charging-frequency-three"
                                width="100%"
                                class="selected"
                            />
                            <img
                                *ngIf="chargingFrequencyActiveTabId == 3"
                                src="./assets/img/cadastre-solaire/electric_vehicule_charging_frequency_one.svg"
                                alt="electric-vehicule-charging-frequency-one"
                                width="100%"
                                class="selected"
                            />
                        </div>

                        <div [ngbNavOutlet]="navRechargingFrequency"></div>
                    </div>
                </div>

                <div class="container-fluid solar-card mt-3" *ngIf="showWarning()">
                    <div class="alert d-flex align-items-center p-0 mb-0" role="alert">
                        <i
                            class="bi bi-exclamation-triangle fa-2x text-st-active flex-shrink-0 me-3"
                        ></i>

                        <div>
                            Les paramètres de recharge que vous avez sélectionnés ne permettent pas
                            de maximiser les bénéfices de votre installation solaire. Pour optimiser
                            la consommation de l’électricité solaire que vous produisez, nous vous
                            recommandons d’adopter les comportements suivants&nbsp;:
                            <ul class="mb-0">
                                <li *ngIf="showChargingSchedule()">Recharge en pleine journée</li>
                                <li *ngIf="showRechargingPoint()">Utiliser une prise renforcée</li>
                                <li *ngIf="showChargingFrequency()">5 recharges par semaine</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer border-top">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-5">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-default w-100 h-100"
                        btnLabel="Retour"
                        (onClick)="cancel()"
                    >
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        btnLabel="Valider ma mobilité électrique"
                        [isDisabled]="!isMobilityUsesFormValid"
                        (onClick)="validate()"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>

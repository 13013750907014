<div class="d-flex flex-column h-100">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <div class="header mb-3">
                <div class="container-fluid">
                    <div class="row d-flex align-items-center solar-card">
                        <div class="col-auto px-0 text-center">
                            <span class="fa-stack">
                                <i class="fa fa-circle-thin fa-stack-2x"></i>
                                <i class="fa fa-info fa-stack-1x"></i>
                            </span>
                        </div>
                        <div class="col">
                            Votre compteur Linky permet d’enregistrer votre consommation au pas de
                            temps horaire. Nous allons comparer cette consommation à votre
                            production solaire pour estimer la part d’énergie que vous allez
                            consommer directement.
                        </div>
                    </div>
                </div>
            </div>

            <div class="body">
                <div class="solar-card mb-3">
                    <p>Pour récupérer le fichier CSV vous pouvez suivre les étapes suivantes :</p>
                    <ul>
                        <li>
                            Connectez-vous sur l'espace client de votre fournisseur d'électricité et
                            assurez-vous que l'enregistrement de votre consommation horaire est
                            activé depuis au moins un an.
                        </li>
                        <li>
                            Téléchargez les données de consommation horaire en choisissant une
                            période d’une année complète.
                        </li>
                    </ul>
                    <p class="mb-0">
                        Pour pouvoir importer votre fichier au format csv, celui-ci doit contenir au
                        moins une année de données. Au plus le fichier sera complet, au mieux nous
                        pourrons évaluer la faisabilité de votre projet.
                    </p>
                </div>

                <button class="btn btn-st-primary w-100" (click)="hiddenFileInput.click();">
                    <input
                        type="file"
                        accept=".csv"
                        (change)="importConsumptionFile($event, hiddenFileInput)"
                        #hiddenFileInput
                        [hidden]="true"
                    />
                    Importer le fichier CSV reçu depuis le site de votre fournisseur d'électricité
                </button>

                <div
                    class="d-flex justify-content-center mt-3"
                    *ngIf="importedElectricLoad.isLoading"
                >
                    <span class="fa fa-spinner fa-pulse text-st-active fs-4"></span>
                </div>

                <div class="solar-card mt-3" *ngIf="importedElectricLoad.isValid">
                    <div>
                        <span class="text-st-active">Le fichier CSV a bien été importé</span>. Le
                        remplissage du fichier est de
                        <span class="text-st-active">
                            {{ importedElectricLoad.fillRate }}&nbsp;% </span
                        >. Les données de consommation retenues couvrent la période du
                        <span class="text-st-active"
                            >{{ importedElectricLoad.start | date:'short' }}</span
                        >
                        au
                        <span class="text-st-active"
                            >{{ importedElectricLoad.end | date:'short' }}</span
                        >.
                    </div>

                    <div
                        class="container-fluid px-0"
                        *ngIf="importedElectricLoad.fillRate < fillRateMin"
                    >
                        <hr />
                        <div class="alert d-flex align-items-center p-0 mb-0" role="alert">
                            <i
                                class="bi bi-exclamation-triangle fa-2x text-st-active flex-shrink-0 me-3"
                            >
                            </i>

                            <div>
                                Attention, le fichier comporte de nombreuses données manquantes. Un
                                traitement est appliqué pour les compléter afin que vous puissiez
                                poursuivre votre simulation. Cependant, l’estimation de votre
                                consommation annuelle risque d’être imprécise impactant les
                                résultats de la simulation.
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="alert alert-danger d-flex align-items-center p-3 mb-0 mt-3"
                    role="alert"
                    *ngIf="importedElectricLoad.error"
                >
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    <span class="ms-3"> {{ importedElectricLoad.error }} </span>
                </div>
            </div>
        </div>
    </div>

    <div class="footer border-top">
        <div class="container-fluid py-3">
            <div class="row">
                <div class="col-5">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-default w-100 h-100"
                        btnLabel="Retour"
                        (onClick)="cancel()"
                    >
                    </stButton>
                </div>
                <div class="col-7">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        btnLabel="Valider mes consommations"
                        (onClick)="goToStage('consumptionStage')"
                        [isDisabled]="!importedElectricLoad.isValid"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-danger" *ngIf="hasError" role="alert">
    <strong>Erreur</strong> dans le lancement de la simulation
</div>
<div class="ligneFlexStartCentered panDonTitleTheme" (click)="showCas = !showCas">
    <div class="fa fa-caret-down" style="width: 20px" [hidden]="!showCas"></div>
    <div class="fa fa-caret-right" style="width: 20px" [hidden]="showCas"></div>
    <div>Choix d'un cas d'usage</div>
</div>
<div class="toggleContenu contenuThemIndicSM" style="font-size: 12px" [hidden]="!showCas">
    <div class="row">
        <ng-template #tooltipContent>
            <div [innerHTML]="caseParameters.popoverInfo"></div>
        </ng-template>
        <div class="col-1" style="margin-top: 10px">
            <i
                class="fa fa-info-circle"
                [ngbTooltip]="tooltipContent"
                placement="start"
                container="body"
            ></i>
        </div>
        <div class="col-6" style="margin-top: 10px"><span>Approche de la simulation :</span></div>
        <div class="col-5">
            <stSelect
                labelField="libelle"
                valueField="fullObject"
                [data]="caseParameters.availableCases"
                [(ngModel)]="caseParameters.selectedCase"
                (change)="updateCas()"
            ></stSelect>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center" style="margin: 10px">
            <strong><em>{{caseParameters.selectedCase.message}}</em></strong>
        </div>
    </div>
</div>
<div class="ligneFlexStartCentered panDonTitleTheme" (click)="showSituation = !showSituation">
    <div class="fa fa-caret-down" style="width: 20px" [hidden]="!showSituation"></div>
    <div class="fa fa-caret-right" style="width: 20px" [hidden]="showSituation"></div>
    <div>Périmètre de la simulation</div>
</div>
<div class="toggleContenu contenuThemIndicSM" style="font-size: 12px" [hidden]="!showSituation">
    <singleInput
        *ngFor="let input of simulationParameters | filterBy : ['type'] : 'number'"
        [value]="input.value"
        [popoverInfo]="input.popoverInfo"
        [label]="input.libelle"
    ></singleInput>
    <div *ngFor="let input of simulationParameters | filterBy : ['type'] : 'select'">
        <div class="row">
            <ng-template #tooltipContent2>
                <div [innerHTML]="input.popoverInfo"></div>
            </ng-template>
            <div class="col-1" style="margin-top: 10px">
                <i
                    class="fa fa-info-circle"
                    [ngbTooltip]="tooltipContent2"
                    placement="start"
                    container="body"
                ></i>
            </div>
            <div class="col-8" style="margin-top: 10px"><span>{{input.libelle}}</span></div>
            <div class="col-3">
                <stSelect
                    label-field="libelle"
                    [data]="input.options"
                    (change)="updateSituation(input)"
                    [(ngModel)]="input.value"
                ></stSelect>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="hasPotentialCostMessage">
        <div class="col-12 text-center" style="margin: 10px">
            <strong
                ><em
                    >Seuls les bâtiments définis comme consommateur obligé ou consommateur possible
                    dans l'onglet « Import données : définition parc bâti » peuvent rentrer dans le
                    périmètre des groupements simulés
                </em></strong
            >
        </div>
    </div>
    <div *ngFor="let input of simulationParameters | filterBy : ['type']: 'checkbox'">
        <div class="row">
            <div class="col-1" style="margin-top: 10px">
                <ng-template #tooltipContent3>
                    <div [innerHTML]="input.popoverInfo"></div>
                </ng-template>
                <i
                    class="fa fa-info-circle"
                    [ngbTooltip]="tooltipContent3"
                    placement="start"
                    container="body"
                ></i>
            </div>
            <div class="col-11" style="margin-top: 10px">
                <span>{{ input.libelle }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-6" *ngFor="let opt of input.options">
                <stCheckbox
                    innerId="atac-parameters-{{ input.id }}-{{ opt.value }}"
                    [(ngModel)]="opt.value"
                    (change)="ifProducteurCheckConsommateur(input.id, opt)"
                >
                    {{ opt.libelle }}
                </stCheckbox>
            </div>
        </div>
    </div>
</div>

<div class="ligneFlexStartCentered panDonTitleTheme" (click)="showEconomique=!showEconomique">
    <div class="fa fa-caret-down" style="width: 20px" [hidden]="!showEconomique"></div>
    <div class="fa fa-caret-right" style="width: 20px" [hidden]="showEconomique"></div>
    <div>Hypothèses économiques</div>
</div>
<div class="toggleContenu contenuThemIndicSM" [hidden]="!showEconomique" style="font-size: 12px">
    <singleInput
        *ngFor="let input of economicParameters | filterBy : ['type'] : 'number'"
        [value]="input.value"
        [popoverInfo]="input.popoverInfo"
        [label]="input.libelle"
    ></singleInput>
    <div *ngFor="let input of economicParameters | filterBy : ['type']  : 'select'">
        <div class="row">
            <div class="col-1" style="margin-top: 10px">
                <ng-template #tooltipContent4>
                    <div [innerHTML]="input.popoverInfo"></div>
                </ng-template>
                <i
                    class="fa fa-info-circle"
                    [ngbTooltip]="tooltipContent4"
                    placement="start"
                    container="body"
                ></i>
            </div>
            <div class="col-8" style="margin-top: 10px">
                <span>{{ input.libelle }}</span>
            </div>
            <div class="col-3">
                <stSelect
                    label-field="libelle"
                    [data]="input.options"
                    [(ngModel)]="input.value"
                ></stSelect>
            </div>
        </div>
    </div>
    <div *ngFor="let input of economicParameters | filterBy : ['type'] : 'menu'">
        <div class="row" (click)="showInvestCost=!showInvestCost">
            <div class="col-1" style="margin-top: 10px">
                <ng-template #tooltipContent5>
                    <div [innerHTML]="input.popoverInfo"></div>
                </ng-template>
                <i
                    class="fa fa-info-circle"
                    [ngbTooltip]="tooltipContent5"
                    placement="start"
                    container="body"
                ></i>
            </div>
            <div class="col-1" style="margin-top: 10px">
                <div class="ligneFlexStartCentered panDonTitleTheme">
                    <div
                        class="fa fa-caret-down"
                        style="width: 10px"
                        [hidden]="!showInvestCost"
                    ></div>
                    <div
                        class="fa fa-caret-right"
                        style="width: 10px"
                        [hidden]="showInvestCost"
                    ></div>
                </div>
            </div>
            <div class="col-6" style="margin-top: 10px; cursor: pointer">
                <span>{{ input.libelle }}</span>
            </div>
            <div class="col-3"></div>
        </div>
        <div class="toggleContenu contenuThemIndicSM" [hidden]="!showInvestCost">
            <div *ngFor="let input_2 of input.item">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-7" style="margin-top: 10px">
                        <span>{{ input_2.libelle }}</span>
                    </div>
                    <div class="col-3">
                        <stInput type="number" [(ngModel)]="input_2.value"></stInput>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" style="margin-top: 10px; margin-bottom: 10px">
    <div class="col-12 text-center">
        <stButton
            btnLabel="Lancer la simulation"
            btnClass="btn-success"
            [iconRight]="iconClass"
            [isDisabled]="isLoading || launchNotAllowed()"
            (click)="launchSimulation()"
            [ngbTooltip]="launchAlert"
            placement="top"
            container="body"
        >
        </stButton>
    </div>
</div>

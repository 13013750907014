import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';

import { NgbCollapseModule, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgPipesModule } from 'ngx-pipes';
import { NgChartsModule } from 'ng2-charts';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import {
    SeparatorPipe,
    SortByPipe,
    AlphabeticallySortByPipe,
    BypassHtmlSanitizerPipe,
} from '../pipes/pipes';

import { StAccordionComponent } from './forms/st-accordion/st-accordion.component';
import { StButtonComponent } from './forms/st-button/st-button.component';
import { StCalendarComponent } from './forms/st-calendar/st-calendar.component';
import { StCheckboxComponent } from './forms/st-checkbox/st-checkbox.component';
import { StColorComponent } from './forms/st-color/st-color.component';
import { StInputComponent } from './forms/st-input/st-input.component';
import { StInputSearchComponent } from './forms/st-input-search/st-input-search.component';
import { StSelectComponent } from './forms/st-select/st-select.component';
import { StPaginationComponent } from './forms/st-pagination/st-pagination.component';
import { StRadioComponent } from './forms/st-radio/st-radio.component';
import { StRangeComponent } from './forms/st-range/st-range.component';

import { StStackedBarChartComponent } from './st-stacked-bar-chart/st-stacked-bar-chart.component';
import { StBarChartComponent } from './st-bar-chart/st-bar-chart.component';
import { StLineChartComponent } from './st-line-chart/st-line-chart.component';
import { StDoughnutComponent } from './st-doughnut/st-doughnut.component';
import { StHeader } from './st-header/st-header.component';
import { TutorialModalComponent } from './modals/tutorial/tutorial-modal.component';
import { StProgressBarComponent } from './st-progressbar/st-progressbar.component';
import { StTabComponent } from './st-tab/st-tab.component';
import { SvgComponent } from './st-svg/st-svg.component';
// import { StTable } from './st-table/st-table.component';

import { ConfirmationModalComponent } from '../controllers/main/modals/confirmation/confirmation-modal.component';
import { MapParametersModalComponent } from '../controllers/main/components/module-indicator-parameters/modals/parameters-modal.component';
import { StNavbarComponent } from '../controllers/admin/navbar/navbar.component';

@NgModule({
    declarations: [
        StAccordionComponent,
        StButtonComponent,
        StCalendarComponent,
        StTabComponent,
        SvgComponent,
        // StTable,
        StCheckboxComponent,
        StColorComponent,
        StInputComponent,
        StPaginationComponent,
        StInputSearchComponent,
        StRadioComponent,
        StRangeComponent,
        StSelectComponent,
        StHeader,
        SeparatorPipe,
        SortByPipe,
        AlphabeticallySortByPipe,
        BypassHtmlSanitizerPipe,
        StProgressBarComponent,
        StBarChartComponent,
        StStackedBarChartComponent,
        StLineChartComponent,
        StDoughnutComponent,
        StNavbarComponent,
        TutorialModalComponent,
        ConfirmationModalComponent,
        MapParametersModalComponent,
    ],
    imports: [
        BrowserModule,
        UpgradeModule,
        NgbModule,
        FormsModule,
        NgPipesModule,
        NgbCollapseModule,
        NgbDropdownModule,
        NgxSmartModalModule.forRoot(),
        NgChartsModule,
    ],
    exports: [
        StAccordionComponent,
        StButtonComponent,
        StCalendarComponent,
        StTabComponent,
        SvgComponent,
        // StTable,
        StCheckboxComponent,
        StColorComponent,
        StInputComponent,
        StPaginationComponent,
        StInputSearchComponent,
        StRadioComponent,
        StRangeComponent,
        StSelectComponent,
        StHeader,
        SeparatorPipe,
        SortByPipe,
        AlphabeticallySortByPipe,
        BypassHtmlSanitizerPipe,
        StProgressBarComponent,
        StBarChartComponent,
        StStackedBarChartComponent,
        StLineChartComponent,
        StDoughnutComponent,
        StNavbarComponent,
        TutorialModalComponent,
        ConfirmationModalComponent,
    ],
    providers: [],
    bootstrap: [],
})
export class SharedModule {}

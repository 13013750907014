import { Component, Inject, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ModuleService } from 'src/app/services/module.service';

import { ContactModalComponent } from './modals/contact-modal.component';

@Component({
    selector: 'info',
    templateUrl: './info.template.html',
    styleUrls: ['./info.component.css'],
})
export class StInfoComponent implements OnInit {
    public customInfo: string;

    constructor(
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(ModuleService) public moduleService: ModuleService,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        const preferences = this.localStorageService.get('preferences');
        if (preferences) {
            const modularity = preferences.modularity;
            this.customInfo = modularity.info;
        }
    }

    open() {
        this.modalService.open(ContactModalComponent);
    }
}

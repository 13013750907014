<stTab
    class="prosperReseaux"
    label="PROSPER Réseaux"
    [visible]="moduleService.isModuleActive.prosperReseaux"
>
    <ng-container *ngIf="show.main">
        <div class="d-flex flex-column h-100">
            <div class="header">
                <div id="pr-element-title" class="pr-card py-15">
                    <ng-container *ngIf="!prService.miscInfo.isInitialized">
                        <div class="d-flex align-items-center h-100">
                            <h4 class="text-center st-text-body my-0 w-100">
                                <span> Pour simuler un projet, </span>
                                <span class="text-st-active">
                                    sélectionner un élement du réseau sur la carte.
                                </span>
                            </h4>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="prService.miscInfo.isInitialized">
                        <div class="d-flex align-items-center justify-content-between h-100">
                            <span> {{ prService.miscInfo.selectedElement.typeLabel }} : </span>
                            <span class="text-st-active">
                                {{ prService.miscInfo.selectedElement.label }}
                            </span>
                            <span>
                                <stButton
                                    btnClass="btn-bg-transparent st-text-body"
                                    iconLeft="fa fa-bullseye"
                                    ngbTooltip="Trouver sur la carte"
                                    placement="bottom"
                                    (onClick)="targetElementOnMap(prService.miscInfo.selectedElement)"
                                >
                                </stButton>
                                <stButton
                                    btnClass="btn-bg-transparent st-text-body"
                                    iconLeft="fa fa-pencil"
                                    ngbTooltip="Modifier cet élement"
                                    placement="bottom"
                                    (onClick)="editElement(prService.miscInfo.selectedElement)"
                                >
                                </stButton>
                            </span>
                        </div>
                    </ng-container>
                </div>

                <div id="pr-options" class="container-fluid py-3">
                    <div class="row gx-2 flex-grow-1">
                        <div class="col" *ngIf="isProsperActionsScenariosAvailable">
                            <stButton
                                btnLabel="Importer un scénario PROSPER Actions"
                                btnClass="btn-outline-st-primary w-100 px-1"
                                (onClick)="switchTo('prosperActionsScenario')"
                            >
                            </stButton>
                        </div>
                        <div class="col">
                            <stButton
                                btnLabel="Rechercher un élément réseau"
                                btnClass="btn-outline-st-primary w-100"
                                (onClick)="switchTo('searchElement')"
                            ></stButton>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body flex-grow-1 overflow-auto">
                <div class="container-fluid mb-3">
                    <h5 class="form-title my-2">Modifier les éléments réseaux sélectionnés</h5>

                    <div id="main-accordion">
                        <ngb-accordion
                            #acc="ngbAccordion"
                            [activeIds]="prService.collapse.prTypes"
                            (panelChange)="prTypeAccordionOnChange($event)"
                        >
                            <ng-container
                                *ngFor="let typeElement of prService.elementsToModifyByType"
                            >
                                <ngb-panel
                                    id="panel-{{ typeElement.id }}"
                                    *ngIf="typeElement.elements.length"
                                >
                                    <ng-template ngbPanelHeader>
                                        <button
                                            ngbPanelToggle
                                            class="accordion-button element-type py-2"
                                            [ngClass]="{'active': typeElement.isActive}"
                                        >
                                            {{ typeElement.label }} ({{ typeElement.elements.length
                                            }})
                                        </button>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <ngb-accordion
                                            #accElement="ngbAccordion"
                                            [activeIds]="prService.collapse.elements"
                                            (panelChange)="elementAccordionOnChange($event)"
                                        >
                                            <ng-container
                                                *ngFor="let element of typeElement.elements"
                                            >
                                                <ngb-panel id="panel-{{ element.id }}">
                                                    <ng-template ngbPanelHeader>
                                                        <button
                                                            ngbPanelToggle
                                                            class="accordion-button element-label py-2"
                                                            [ngClass]="{'active': isElementSelected(element)}"
                                                        >
                                                            {{ element.label }}
                                                        </button>
                                                        <div
                                                            class="element-options d-flex align-items-center"
                                                        >
                                                            <stButton
                                                                btnClass="btn-bg-transparent st-text-body d-flex align-items-center"
                                                                iconLeft="fa fa-bullseye"
                                                                ngbTooltip="Trouver sur la carte"
                                                                placement="bottom"
                                                                (onClick)="targetElementOnMap(element)"
                                                            >
                                                            </stButton>
                                                            <button
                                                                type="button"
                                                                class="btn btn-bg-transparent st-text-body d-flex align-items-center"
                                                                (click)="removeHypothesis(element.typeId, element)"
                                                                ngbTooltip="Supprimer cet élement"
                                                                placement="bottom"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="18"
                                                                    height="18"
                                                                    fill="currentColor"
                                                                    class="bi bi-x"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path
                                                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </ng-template>

                                                    <ng-template ngbPanelContent>
                                                        <div
                                                            class="text-center"
                                                            *ngIf="!element.isHypothesisAvailable"
                                                        >
                                                            <i>
                                                                Aucun calcul dynamique n'est
                                                                disponible pour cet élement réseau.
                                                            </i>
                                                            <i
                                                                class="fa fa-question-circle ms-4 cursor-pointer"
                                                                ngbPopover="Accéder au calcul dynamique en sélectionnant un indicateur associé à cet élément du réseau."
                                                                [placement]="['right', 'right-top', 'right-bottom']"
                                                                container="body"
                                                                triggers="mouseenter:mouseleave"
                                                            ></i>
                                                        </div>
                                                        <ng-container
                                                            *ngIf="element.isHypothesisAvailable"
                                                        >
                                                            <ng-container
                                                                *ngFor="let hypothesis of element.hypothesis"
                                                            >
                                                                <div
                                                                    class="form-group hypothesis-field"
                                                                    *ngIf="hypothesis.isAvailable"
                                                                >
                                                                    <label
                                                                        for="hypothesis-{{ hypothesis.id }}"
                                                                        class="col-6 pe-0 control-label text-start fw-normal"
                                                                    >
                                                                        <span>
                                                                            {{ hypothesis.label }}
                                                                        </span>
                                                                    </label>
                                                                    <div class="col-5">
                                                                        <ng-container
                                                                            *ngIf="!hypothesis.options"
                                                                        >
                                                                            <stInput
                                                                                class="primary"
                                                                                innerId="hypothesis-{{ hypothesis.id }}"
                                                                                type="number"
                                                                                [min]="0"
                                                                                [step]="hypothesis.step"
                                                                                [placeholder]="hypothesis.referenceLabel"
                                                                                [(ngModel)]="hypothesis.value"
                                                                            >
                                                                            </stInput>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="hypothesis.options"
                                                                        >
                                                                            <stInputSearch
                                                                                class="dropdown w-100"
                                                                                innerId="hypothesis-{{ hypothesis.id }}"
                                                                                [clear]="false"
                                                                                [placeholder]="hypothesis.referenceLabel"
                                                                                [options]="hypothesis.options"
                                                                                label="label"
                                                                                [initValue]="hypothesis.value"
                                                                                (onSelect)="handleSelectCategoricalHypothesis($event, hypothesis)"
                                                                                (onBlur)="handleBlurCategoricalHypothesis($event, hypothesis)"
                                                                            >
                                                                            </stInputSearch>
                                                                        </ng-container>
                                                                    </div>
                                                                    <div
                                                                        class="col-1 px-0 text-center"
                                                                    >
                                                                        {{ hypothesis.unit }}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <div
                                                                class="form-group hypothesis-field"
                                                                *ngIf="['15', '05'].includes(typeElement.id)"
                                                            >
                                                                <label
                                                                    for="apply-modifications"
                                                                    class="col-5 control-label text-start fw-normal pe-0"
                                                                >
                                                                    <span>
                                                                        Appliquer les modifications
                                                                    </span>
                                                                </label>
                                                                <div class="col-1 text-right">
                                                                    <span
                                                                        class="fa fa-spinner fa-2x fa-pulse text-st-active"
                                                                        *ngIf="element.isLineElementsLoading"
                                                                    ></span>
                                                                </div>
                                                                <div class="col-5 d-flex">
                                                                    <div
                                                                        class="btn-group w-100"
                                                                        id="apply-modifications"
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            class="btn btn-st-primary py-1"
                                                                            (click)="applyChanges(element.applyChanges.value, element)"
                                                                        >
                                                                            {{
                                                                            element.applyChanges.label
                                                                            }}
                                                                        </button>
                                                                        <div
                                                                            class="btn-group"
                                                                            ngbDropdown
                                                                            role="group"
                                                                        >
                                                                            <button
                                                                                type="button"
                                                                                class="btn btn-st-primary dropdown-toggle-split py-1"
                                                                                ngbDropdownToggle
                                                                            ></button>
                                                                            <div
                                                                                class="dropdown-menu"
                                                                                ngbDropdownMenu
                                                                            >
                                                                                <ng-container
                                                                                    *ngFor="let option of userSelection.applyChangesOptions"
                                                                                >
                                                                                    <button
                                                                                        class="cursor-pointer dropdown-item"
                                                                                        (click)="applyChanges(option.value, element)"
                                                                                        ngbDropdownItem
                                                                                    >
                                                                                        {{
                                                                                        option.label
                                                                                        }}
                                                                                    </button>
                                                                                </ng-container>
                                                                            </div>
                                                                        </div>

                                                                        <!-- <div class="dropdown-menu" ngbDropdownMenu>
                                          <ng-container *ngFor="let option of userSelection.applyChangesOptions">
                                            <div>
                                              <a ng-href="" class="cursor-pointer dropdown-item" target=_self
                                                (click)="applyChanges(option.value, element)" ngbDropdownItem>
                                                {{ option.label }}
                                              </a>
                                            </div>
                                          </ng-container>
                                        </div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <div class="mt-3">
                                                            <stButton
                                                                *ngIf="['05', '15'].includes(typeElement.id) && !isDrawingEnable"
                                                                btnLabel="Ajouter une extension"
                                                                btnClass="btn-st-primary w-100 py-1"
                                                                iconLeft="fa fa-pencil pe-3"
                                                                (onClick)="addExtension(element.id, typeElement.id)"
                                                                [isDisabled]="!element.isDrawingAvailable"
                                                                [ngbTooltip]="element.drawingTooltip"
                                                                [disableTooltip]="element.isDrawingAvailable"
                                                                placement="bottom"
                                                                container="body"
                                                            >
                                                            </stButton>
                                                            <stButton
                                                                *ngIf="typeElement.id == '09' && !isDrawingEnable"
                                                                btnLabel="Ajouter un nouveau départ BT"
                                                                btnClass="btn-st-primary w-100 py-1"
                                                                iconLeft="fa fa-pencil pe-3"
                                                                (onClick)="addStart(element.id, typeElement.id)"
                                                                [isDisabled]="!element.isDrawingAvailable"
                                                                [ngbTooltip]="element.drawingTooltip"
                                                                [disableTooltip]="element.isDrawingAvailable"
                                                                placement="bottom"
                                                                container="body"
                                                            >
                                                            </stButton>
                                                            <stButton
                                                                *ngIf="typeElement.id == '10' && !isDrawingEnable"
                                                                btnLabel="Ajouter un nouveau transformateur et départ BT"
                                                                btnClass="btn-st-primary w-100 py-1"
                                                                iconLeft="fa fa-pencil pe-3"
                                                                (onClick)="addHtaBtTransformer(element.id, typeElement.id)"
                                                                [isDisabled]="!element.isDrawingAvailable"
                                                                [ngbTooltip]="element.drawingTooltip"
                                                                [disableTooltip]="element.isDrawingAvailable"
                                                                placement="bottom"
                                                                container="body"
                                                            >
                                                            </stButton>
                                                            <stButton
                                                                *ngIf="typeElement.id == '20' && !isDrawingEnable"
                                                                btnLabel="Ajouter un nouveau départ HTA"
                                                                btnClass="btn-st-primary w-100 py-1"
                                                                iconLeft="fa fa-pencil pe-3"
                                                                (onClick)="addStart(element.id, typeElement.id)"
                                                                [isDisabled]="!element.isDrawingAvailable"
                                                                [ngbTooltip]="element.drawingTooltip"
                                                                [disableTooltip]="element.isDrawingAvailable"
                                                                placement="bottom"
                                                                container="body"
                                                            >
                                                            </stButton>
                                                            <stButton
                                                                *ngIf="isDrawingEnable"
                                                                btnLabel="Annuler"
                                                                btnClass="btn-st-primary w-100 py-1"
                                                                iconLeft="fa fa-pencil pe-3"
                                                                (onClick)="stopDraw()"
                                                            >
                                                            </stButton>
                                                        </div>
                                                    </ng-template>
                                                </ngb-panel>
                                            </ng-container>
                                        </ngb-accordion>
                                    </ng-template>
                                </ngb-panel>
                            </ng-container>
                        </ngb-accordion>
                    </div>
                </div>
            </div>

            <div class="footer border-top" *ngIf="prService.miscInfo.selectedElement">
                <div class="container-fluid py-2">
                    <div class="row">
                        <div class="col-5">
                            <stButton
                                btnClass="btn-st-default w-100"
                                btnLabel="Réinitialiser le réseau"
                                (onClick)="reset()"
                            >
                            </stButton>
                        </div>
                        <div class="col-7">
                            <stButton
                                btnClass="btn-st-active w-100"
                                btnLabel="Simuler les modifications"
                                (onClick)="update()"
                                [isDisabled]="!isAnyHypothesisAvailable || isSimulationRunning"
                            >
                            </stButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="show.prosperActionsScenario">
        <prosperActionsScenario class="flex-grow-1 overflow-auto" [show]="show">
        </prosperActionsScenario>
    </ng-container>

    <ng-container *ngIf="show.searchElement">
        <prosperReseauxSearchElement class="flex-grow-1 overflow-auto" [show]="show">
        </prosperReseauxSearchElement>
    </ng-container>
</stTab>

<div class="animate-switch-container h-100" [ngSwitch]="stage">
    <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'equipmentsStage'">
        <cadastreSolairePvSurplusEquipments
            class="flex-grow-1 overflow-auto"
        ></cadastreSolairePvSurplusEquipments>
    </div>

    <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'importStage'">
        <cadastreSolairePvSurplusImport
            class="flex-grow-1 overflow-auto"
        ></cadastreSolairePvSurplusImport>
    </div>

    <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'electricVehiculeStage'">
        <cadastreSolairePvSurplusElectricVehicule
            class="flex-grow-1 overflow-auto"
        ></cadastreSolairePvSurplusElectricVehicule>
    </div>

    <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'consumptionStage'">
        <cadastreSolairePvSurplusConsumption
            class="flex-grow-1 overflow-auto"
        ></cadastreSolairePvSurplusConsumption>
    </div>

    <div class="animate-switch d-flex flex-column h-100" *ngSwitchCase="'simulationStage'">
        <cadastreSolairePvSurplusSimulation
            class="flex-grow-1 overflow-auto"
        ></cadastreSolairePvSurplusSimulation>
    </div>
</div>

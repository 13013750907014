<div class="d-flex flex-column h-100" id="cs-new-element-panel">
    <div class="flex-grow-1 overflow-auto">
        <div class="w-100 p-3">
            <ng-container *ngIf="isZoneConstraint">
                <div class="solar-card text-st-active mb-3">
                    L'emplacement ciblé se trouve dans une zone sujette à des contraintes
                    architecturales. Rapprochez-vous de votre mairie afin d'étudier la faisabilité
                    de votre projet solaire.
                </div>
            </ng-container>

            <ng-container *ngIf="newRoofFormGroup">
                <form [formGroup]="newRoofFormGroup">
                    <div class="container-fluid solar-card">
                        <h5 class="text-st-primary mb-3">
                            Description de mon projet d'installation solaire
                        </h5>

                        <div id="installation-type-row" class="row d-flex align-items-center mb-3">
                            <div class="col-auto">
                                <st-svg svgName="solar_panel" [size]="26"></st-svg>
                            </div>

                            <div class="col">
                                <div class="row d-flex align-items-center">
                                    <div class="col-sm">
                                        <div class="row d-flex align-items-center">
                                            <label class="col" for="new-roof-installation"
                                                >Type d'installation</label
                                            >
                                            <!-- <div class="col-auto">
                                                <i class="fa fa-info-circle fs-body"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-sm">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-10">
                                                <stSelect
                                                    formControlName="installationType"
                                                    innerId="new-roof-installation"
                                                    [data]="installationTypeOptions"
                                                    labelField="label"
                                                    valueField="value"
                                                    type="primary"
                                                >
                                                </stSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="orientation-row" class="row d-flex align-items-center mb-3">
                            <div class="col-auto">
                                <st-svg svgName="compass" [size]="26"></st-svg>
                            </div>

                            <div class="col">
                                <div class="row d-flex align-items-center">
                                    <div class="col-sm">
                                        <div class="row d-flex align-items-center">
                                            <label class="col" for="new-roof-orientation"
                                                >Orientation</label
                                            >
                                            <!-- <div class="col-auto">
                                                <i class="fa fa-info-circle fs-body"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-sm">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-10">
                                                <stSelect
                                                    formControlName="orientation"
                                                    innerId="new-roof-orientation"
                                                    [data]="orientationOptions"
                                                    labelField="label"
                                                    valueField="value"
                                                    type="primary"
                                                >
                                                </stSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="tilt-row" class="row d-flex align-items-center mb-3">
                            <div class="col-auto">
                                <st-svg svgName="square" [size]="26"></st-svg>
                            </div>

                            <div class="col">
                                <div class="row d-flex align-items-center">
                                    <div class="col-sm">
                                        <div class="row d-flex align-items-center">
                                            <label class="col" for="new-roof-tilt"
                                                >Inclinaison de la couverture</label
                                            >
                                            <!-- <div class="col-auto">
                                                <i class="fa fa-info-circle fs-body"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-sm">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-10">
                                                <stInput
                                                    formControlName="tilt"
                                                    innerId="new-roof-tilt"
                                                    placeholder="Inclinaison"
                                                    class="primary"
                                                    type="number"
                                                    [min]="0"
                                                    [max]="90"
                                                ></stInput>
                                            </div>
                                            <div class="col-2">°</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="available-area-row" class="row d-flex align-items-center mb-3">
                            <div class="col-auto">
                                <st-svg svgName="area" [size]="26"></st-svg>
                            </div>

                            <div class="col">
                                <div class="row d-flex align-items-center">
                                    <div class="col-sm">
                                        <div class="row d-flex align-items-center">
                                            <label class="col" for="new-roof-usable-area"
                                                >Surface disponible</label
                                            >
                                            <!-- <div class="col-auto">
                                                <i class="fa fa-info-circle fs-body"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-sm">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-10">
                                                <stInput
                                                    formControlName="usableRoofArea"
                                                    innerId="new-roof-usable-area"
                                                    placeholder="Surface"
                                                    class="primary"
                                                    type="number"
                                                    [min]="1"
                                                ></stInput>
                                            </div>
                                            <div class="col-2">m<sup>2</sup></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="height-row" class="row d-flex align-items-center">
                            <div class="col-auto">
                                <st-svg svgName="height" [size]="26"></st-svg>
                            </div>

                            <div class="col">
                                <div class="row d-flex align-items-center">
                                    <div class="col-sm">
                                        <div class="row d-flex align-items-center">
                                            <label class="col" for="new-roof-height"
                                                >Hauteur de l'installation</label
                                            >
                                            <div class="col-auto px-0 text-center">
                                                <stButton
                                                    class="btn-info-modal"
                                                    btnClass="d-flex align-items-center py-0"
                                                    iconLeft="bi bi-info-circle-fill text-st-secondary fs-5"
                                                    iconLeftHover="bi bi-info-circle fs-5"
                                                    (onClick)="openHeightModal()"
                                                    title="Plus d'information"
                                                ></stButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-10">
                                                <stInput
                                                    formControlName="height"
                                                    innerId="new-roof-height"
                                                    placeholder="Hauteur"
                                                    class="primary"
                                                    type="number"
                                                    [min]="1"
                                                ></stInput>
                                            </div>
                                            <div class="col-2">m</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>

    <div class="footer border-top">
        <div class="container-fluid text-center py-3">
            <div class="row">
                <div class="col-12">
                    <stButton
                        class="btn-st-lg"
                        btnClass="btn-st-primary w-100"
                        btnLabel="Démarrer ma simulation de projet solaire"
                        (onClick)="startSimulation()"
                        [isDisabled]="!isFormValid"
                    >
                    </stButton>
                </div>
            </div>
        </div>
    </div>
</div>

<button
    type="button"
    class="btn {{ btnClass }}"
    [disabled]="isDisabled || null"
    (click)="click()"
    [ngClass]="{'st-collapse-button': isCollapseButton, 'is-collapsed': isCollapsed}"
    [attr.data-st-theme]="theme"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
>
    <ng-container *ngIf="(iconLeft || iconSvgLeft) && (iconLeftHover || iconSvgLeftHover)">
        <ng-container *ngIf="!isHovering">
            <i class="{{ iconLeft }}" *ngIf="iconLeft"></i>
            <st-svg [svgName]="iconSvgLeft" [size]="iconSvgSize" *ngIf="iconSvgLeft"></st-svg>
        </ng-container>
        <ng-container *ngIf="isHovering">
            <i class="{{ iconLeftHover }}" *ngIf="iconLeftHover"></i>
            <st-svg
                [svgName]="iconSvgLeftHover"
                [size]="iconSvgSize"
                *ngIf="iconSvgLeftHover"
            ></st-svg>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="(iconLeft || iconSvgLeft) && !(iconLeftHover || iconSvgLeftHover)">
        <i class="{{ iconLeft }}" *ngIf="iconLeft"></i>
        <st-svg [svgName]="iconSvgLeft" [size]="iconSvgSize" *ngIf="iconSvgLeft"></st-svg>
    </ng-container>

    <span>{{ btnLabel }}</span>

    <ng-container *ngIf="(iconRight || iconSvgRight) && (iconRightHover || iconSvgRightHover)">
        <ng-container *ngIf="!isHovering">
            <i class="{{ iconRight }}" *ngIf="iconRight"></i>
            <st-svg [svgName]="iconSvgRight" [size]="iconSvgSize" *ngIf="iconSvgRight"></st-svg>
        </ng-container>
        <ng-container *ngIf="isHovering">
            <i class="{{ iconRightHover }}" *ngIf="iconRightHover"></i>
            <st-svg
                [svgName]="iconSvgRightHover"
                [size]="iconSvgSize"
                *ngIf="iconSvgRightHover"
            ></st-svg>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="(iconRight || iconSvgRight) && !(iconRightHover || iconSvgRightHover)">
        <i class="{{ iconRight }}" *ngIf="iconRight"></i>
        <st-svg [svgName]="iconSvgRight" [size]="iconSvgSize" *ngIf="iconSvgRight"></st-svg>
    </ng-container>
</button>

import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlausibleAnalyticsService } from 'src/app/services/plausible-analytics.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';
import { SolarPvTotalityService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-totality.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { SolarThermalService } from 'src/app/services/cadastre-solaire/cadastre-solaire-thermal.service';

import { CadastreSolaireContactModalComponent } from '../../modals/contact/cadastre-solaire-contact-modal.component';
import { ConfirmationModalComponent } from 'src/app/controllers/main/modals/confirmation/confirmation-modal.component';

@Component({
    selector: 'cadastreSolaireResults',
    templateUrl: './cadastre-solaire-results.template.html',
    styleUrls: ['./cadastre-solaire-results.component.scss'],
})
export class CadastreSolaireResultsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    @Input() pvTotalPowerPeak: any;

    maxResultsCount: number = 4;

    modularity: any;
    isZoneConstraint: boolean;
    tooltipPvInfoDetail: any;
    tooltipPvInvestmentDetail: string;
    tooltipPvProductionDetail: string;
    tooltipPvSellingDetail: string;
    tooltipPvElectricMobility: string;
    tooltipThInfoDetail: string;
    tooltipThInvestmentDetail: string;
    tooltipThInstallationDetail: string;
    tooltipThProductionDetail: string;
    tooltipThEconomyDetail: string;

    tooltipInfoByTechnology: {
        pv: {
            infoDetail: string;
            investmentDetail: string;
            productionDetail: string;
            economyDetail: string;
            electricMobilityDetail: string;
            warning: string;
        };
        thermal: {
            infoDetail: string;
            investmentDetail: string;
            installationDetail: string;
            productionDetail: string;
            economyDetail: string;
            warning: string;
        };
    };
    tooltip: {
        investment: string;
        installation: string;
        production: string;
        economy: string;
        electricMobility: string;
    };
    contactButtonLabel: string;
    currentResult: any;
    totalPowerPeak: number;

    isResultsDownloading: boolean;

    public titleInvestment: string = 'Investissement';
    public titleProduction: string = 'Production';
    public titleEconomy: string = 'Economies';
    public titleInstallation: string = 'Installation';
    public titleElectricMobility: string = 'Mobilité électrique';

    constructor(
        private notification: ToastrService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(ModuleService) private moduleService: ModuleService,
        @Inject(PlausibleAnalyticsService)
        private plausibleAnalyticsService: PlausibleAnalyticsService,
        @Inject(SolarPvSurplusService)
        private solarPvSurplusService: SolarPvSurplusService,
        @Inject(SolarPvTotalityService) private solarPvTotalityService: SolarPvTotalityService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(SolarThermalService) private solarThermalService: SolarThermalService,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.modularity = this.localStorageService.get('preferences').modularity.solar;
        this.isZoneConstraint = this.solarService.isZoneConstraint;

        this.tooltipPvInfoDetail = this.solarPvTotalityService.pvInfo.message.info_detail;
        this.tooltipPvInvestmentDetail =
            this.solarPvTotalityService.pvInfo.message.investissement_detail;
        this.tooltipPvProductionDetail =
            this.solarPvTotalityService.pvInfo.message.production_detail;
        this.tooltipPvSellingDetail = this.solarPvTotalityService.pvInfo.message.economie_detail;

        this.tooltipThInfoDetail = this.solarThermalService.thInfo.message.info_detail;
        this.tooltipThInvestmentDetail =
            this.solarThermalService.thInfo.message.investissement_detail;
        this.tooltipThInstallationDetail =
            this.solarThermalService.thInfo.message.installation_detail;
        this.tooltipThProductionDetail = this.solarThermalService.thInfo.message.production_detail;
        this.tooltipThEconomyDetail = this.solarThermalService.thInfo.message.economie_detail;

        this.tooltipInfoByTechnology = {
            pv: {
                infoDetail: this.solarPvTotalityService.pvInfo.message.info_detail,
                investmentDetail: this.solarPvTotalityService.pvInfo.message.investissement_detail,
                productionDetail: this.solarPvTotalityService.pvInfo.message.production_detail,
                economyDetail: this.solarPvTotalityService.pvInfo.message.economie_detail,
                electricMobilityDetail:
                    this.solarPvTotalityService.pvInfo.message.mobilite_electrique_detail,
                warning: this.solarPvTotalityService.pvInfo.message.warning,
            },
            thermal: {
                infoDetail: this.solarThermalService.thInfo.message.info_detail,
                investmentDetail: this.solarThermalService.thInfo.message.investissement_detail,
                installationDetail: this.solarThermalService.thInfo.message.installation_detail,
                productionDetail: this.solarThermalService.thInfo.message.production_detail,
                economyDetail: this.solarThermalService.thInfo.message.economie_detail,
                warning: this.solarThermalService.thInfo.message.warning,
            },
        };

        this.tooltip = {
            investment: "L'investissement",
            installation: "L'installation",
            production: 'La production',
            economy: 'Les économies',
            electricMobility: 'Mobilité électrique',
        };

        const customContactButtonLabel = this.solarService.moduleInfo.contacts_bouton;
        this.contactButtonLabel = customContactButtonLabel
            ? customContactButtonLabel
            : 'Aller plus loin';

        this._initialization();

        this.moduleService.moduleOpenObs$.pipe(takeUntil(this.destroy$)).subscribe((module) => {
            const isModuleLomia = module == 'solar';
            if (isModuleLomia) {
                this._scrollResultsBar();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _scrollResultsBar() {
        const lastResultId = this.solarService.results[this.solarService.results.length - 1].id;
        const inline =
            this.solarService.results.length < this.maxResultsCount ? 'center' : 'nearest';
        setTimeout(
            () =>
                document
                    .getElementById(`simulation-button-${lastResultId}`)
                    .scrollIntoView({ behavior: 'auto', block: 'nearest', inline: inline }),
            0,
        );
    }

    private _initialization() {
        this.solarService.results.forEach((result: any, index: number) => (result.id = index + 1));
        this.currentResult = this.solarService.results[this.solarService.results.length - 1];

        if (this.isCurrentResultSurplus()) {
            const surplusResult = this.currentResult.surplusResult;
            this.solarPvSurplusService.updatePvSurplusResultObs(surplusResult);
        }
    }

    async downloadPdf() {
        try {
            this.isResultsDownloading = true;
            const buffer = await this.solarService.getSolarPdfFile();
            const blob = new Blob([buffer], { type: 'application/pdf' });
            saveAs(blob, 'mes-projets-solaires.pdf');
        } catch (error) {
            console.error('Error downloadPdf', error);
            this.notification.error(
                'Une erreur est survenue. Impossible de télécharger les résultats.',
            );
        } finally {
            this.isResultsDownloading = false;

            const user = this.localStorageService.get('user');
            this.plausibleAnalyticsService.trackEvent('Projet Solaire - PDF', {
                utilisateur_id: user.id,
            });
        }
    }

    loadCurrentResult(result: any) {
        this.currentResult = result;
        this.solarService.technology = result.technology;
        this.solarPvTotalityService.totalPowerPeak = result.totalPowerPeak;

        if (this.isCurrentResultSurplus()) {
            const surplusResult = this.currentResult.surplusResult;
            this.solarPvSurplusService.updatePvSurplusResultObs(surplusResult);
        }
    }

    addSimulation() {
        // reinit imported electric load - user must reupload its csv file (PO spec)
        const isImportedElectricLoadDefined =
            this.solarPvSurplusService.checkIsImportedElectricLoadDefined();
        if (isImportedElectricLoadDefined) {
            this.solarPvSurplusService.initImportedElectricLoad();
            this.solarPvSurplusService.consumptionData.energyConsumed = null;
            this.solarPvSurplusService.consumptionData.subscribedPower = null;
        }
        // reinit electric mobility - user must refill electric mobility form (PO spec)
        if (this.solarPvSurplusService.electricMobilityData.chargingSchedule) {
            this.solarPvSurplusService.initElectricMobilityData();
            this.solarPvSurplusService.consumptionData.electricMobility = {
                annualDistance: null,
                chargingSchedule: null,
                chargingPoint: null,
                chargingFrequency: null,
            };
        }
        this.solarService.updateMainStage('technologyStage');
    }

    openContactModal() {
        const body =
            '<p> Vous voulez en savoir plus sur une installation solaire sur votre logement ? </p>';
        const modalRef = this.modalService.open(CadastreSolaireContactModalComponent);
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            (result) => {},
            () => {},
        );
        modalRef.componentInstance.name = 'confirmationModal';
    }

    openDeleteModal() {
        const body = `
            Vous êtes sur le point de supprimer le résultat de la simulation ${this.currentResult.id}&nbsp;:
            <span class="d-inline text-st-primary">${this.currentResult.label}</span>.
            <div>Cette action est irréversible.</div>
        `;
        const modalRef = this.modalService.open(ConfirmationModalComponent);
        modalRef.componentInstance.name = 'confirmationModal';
        modalRef.componentInstance.header = 'Supprimer une simulation';
        modalRef.componentInstance.body = body;
        modalRef.result.then(
            (result) => this.confirmDelete(),
            () => {},
        );
    }

    confirmDelete() {
        this.solarService.removeResult(this.currentResult);

        if (this.solarService.results.length) {
            this._initialization();
        } else {
            const isNew = this.solarService.checkIfSelectedElementsIsNew();
            if (isNew) {
                this.solarService.updateMainStage('newRoofStage');
            } else {
                this.solarService.updateMainStage('initStage');
            }
        }
    }

    isCurrentResultSurplus(): boolean {
        const hasAccess = this.modularity.simulationCharts;
        const isValorizationSurplus = this.currentResult.valorization == 'Surplus';
        return hasAccess && isValorizationSurplus;
    }

    isElectricMobilityResultsDefined(): boolean {
        const isPvSurplus = !!this.currentResult.surplusResult;
        if (isPvSurplus) {
            return !!this.currentResult.surplusResult.electricMobilityDistanceFromSelfConsumption;
        }
        return false;
    }
}

<div class="modal-header">
    <h4 class="modal-title" id="infoContact">Nous contacter</h4>
    <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
    ></button>
</div>
<div class="modal-body">
    <div class="mb-2">Une demande concernant l'application ?</div>

    <div>Energies Demain</div>
    <div>8 rue Martel</div>
    <div>75010 Paris</div>
    <div>
        Courriel :
        <a [href]="'mailto:' + href">{{ contactMail }}</a>
    </div>
</div>
<div class="modal-footer">
    <stButton
        btnLabel="Fermer"
        btnClass="btn-st-default"
        (onClick)="activeModal.close()"
    ></stButton>
</div>

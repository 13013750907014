import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Indicator } from 'src/app/models/Indicator';

import { EventService } from 'src/app/services/event.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ModuleService } from 'src/app/services/module.service';
import { PlotIndicatorService } from 'src/app/services/plotIndicator/plot-indicator.service';
import { PlotCadastreSolaireIndicatorService } from 'src/app/services/plotIndicator/plot-cadastre-solaire.service';
import { SolarService } from 'src/app/services/cadastre-solaire/cadastre-solaire.service';
import { SolarPvSurplusService } from 'src/app/services/cadastre-solaire/cadastre-solaire-pv-surplus.service';

@Component({
    selector: 'cadastreSolaire',
    templateUrl: './cadastre-solaire.template.html',
})
@UntilDestroy()
export class CadastreSolaireComponent implements OnInit, OnDestroy {
    public modularity: any;

    public mainStage: string = null;
    public title: string;
    public subTitle: string;

    constructor(
        @Inject(EventService) private eventService: EventService,
        @Inject(LocalStorageService) private localStorageService: LocalStorageService,
        @Inject(ModuleService) public moduleService: ModuleService,
        @Inject(PlotCadastreSolaireIndicatorService)
        private plotCadastreSolaireIndicatorService: PlotCadastreSolaireIndicatorService,
        @Inject(PlotIndicatorService) private plotIndicatorService: PlotIndicatorService,
        @Inject(SolarService) public solarService: SolarService,
        @Inject(SolarPvSurplusService) public solarPvSurplusService: SolarPvSurplusService,
    ) {}

    ngOnInit(): void {
        this.modularity = this.localStorageService.get('preferences').modularity.solar;
        this.solarService.technology = 'pv';
        this.solarService.valorization = 'Totality';
        this.plotCadastreSolaireIndicatorService.clearFeatureGroups();

        this._listenToEvents();
        this._initialization();
    }

    ngOnDestroy() {
        this._clear();
    }

    private _listenToEvents() {
        this.solarService.mainStageObs$.pipe(untilDestroyed(this)).subscribe((mainStage) => {
            this.mainStage = mainStage;
            if (mainStage) {
                this.title = this.solarService.getTitleByStage(mainStage);
                this.subTitle = this.solarService.getSubTitleByStage(mainStage);
            }
        });

        this.solarService.pvStageObs$.pipe(untilDestroyed(this)).subscribe((pvStage) => {
            if (pvStage) {
                this.title = this.solarService.getTitleByStage(pvStage);
                this.subTitle = this.solarService.getSubTitleByStage(pvStage);
            }
        });

        this.eventService.indicatorUnploted.pipe(untilDestroyed(this)).subscribe(() => {
            const indicatorPlots: Indicator[] = Object.values(
                this.plotIndicatorService.plotedIndicators,
            );
            const solarIndicatorPlots = indicatorPlots.filter(
                (indicator: Indicator) => indicator.plugin == 'solaire',
            );

            if (solarIndicatorPlots.length) {
                this.solarService.removeAllElements();
                this.plotCadastreSolaireIndicatorService.initFeatureGroups();

                this.solarService.indicatorPlot =
                    solarIndicatorPlots[solarIndicatorPlots.length - 1];
            } else {
                this._clear();
            }
        });

        this.eventService.indicatorPloted
            .pipe(untilDestroyed(this))
            .subscribe((indicatorId: any) => {
                const indicatorPlot = this.plotIndicatorService.plotedIndicators[indicatorId];
                const isSolarIndicator = indicatorPlot.plugin == 'solaire';

                if (isSolarIndicator) {
                    this.plotCadastreSolaireIndicatorService.initFeatureGroups();

                    this.solarService.indicatorPlot = indicatorPlot;
                    const isAnyElementSelected = this.solarService.selectedElements.length > 0;
                    if (!isAnyElementSelected) {
                        this._initialization();
                    }
                }
            });
    }

    private _initialization() {
        this.title = 'Simulation de projet solaire';
        this.mainStage = null;

        this.initSelectionData();
    }

    private _clear() {
        this.plotCadastreSolaireIndicatorService.clear();
        this.initSelectionData();
        this.solarService.updateMainStage(null);
        this.solarService.indicatorPlot = null;
    }

    initSelectionData() {
        this.solarService.initSelectionInfo();
        this.solarService.initResults();
        this.solarPvSurplusService.initTypologyData();
        this.solarPvSurplusService.initEquipmentsData();
        this.solarPvSurplusService.initElectricMobilityData();
        this.solarPvSurplusService.initConsumptionData();
        this.solarPvSurplusService.initImportedElectricLoad();
        this.solarPvSurplusService.initEquipments();
    }
}

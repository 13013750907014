<stHeader data-is-admin-page="true"></stHeader>
<stNavbar></stNavbar>

<div id="user-update" class="container-fluid py-4">
    <h3 class="text-st-secondary my-5">
        <span class="fa-stack fa-lg text-st-secondary">
            <i class="fa fa-circle-thin fa-stack-2x"></i>
            <i class="fa fa-users fa-stack-1x"></i>
        </span>
        Utilisateur - Consulter / Modifier
    </h3>

    <hr class="separator" />

    <div id="general-info" class="container-fluid">
        <h4 class="d-inline-block mt-0">
            <b>Informations personnelles</b>
        </h4>
        <ng-template #tooltipContent>
            <div>Modifier</div>
        </ng-template>
        <span
            class="cursor-pointer ms-5"
            *ngIf="!generalInfoManagement.isEditing"
            (click)="generalInfoManagement.isEditing = true"
            [ngbTooltip]="tooltipContent"
        >
            <i class="fa fa-pencil"></i>
        </span>

        <div class="container-fluid">
            <form name="_nomFG" novalidate>
                <div
                    class="row mb-3"
                    [ngClass]="{'d-flex align-items-center': !generalInfoManagement.isEditing}"
                >
                    <label
                        for="user-surname"
                        class="col-3 control-label"
                        [ngClass]="{'py-0': !generalInfoManagement.isEditing}"
                    >
                        Prénom<sup>*</sup> :
                    </label>
                    <div class="col-3" *ngIf="!generalInfoManagement.isEditing">
                        {{ generalInfo.surname }}
                    </div>
                    <div class="col-3" *ngIf="generalInfoManagement.isEditing">
                        <stInput
                            type="text"
                            innerId="user-surname"
                            name="surname"
                            [(ngModel)]="generalInfoTmp.surname"
                            [isDisabled]="generalInfoManagement.isSaving"
                            required
                        >
                        </stInput>
                        <i class="invalid-feedback" *ngIf="!generalInfoTmp.surname">
                            Ce champ est requis.
                        </i>
                    </div>
                </div>

                <div
                    class="row mb-3"
                    [ngClass]="{'d-flex align-items-center': !generalInfoManagement.isEditing}"
                >
                    <label
                        for="user-surname"
                        class="col-3 control-label"
                        [ngClass]="{'py-0': !generalInfoManagement.isEditing}"
                    >
                        Nom<sup>*</sup> :
                    </label>
                    <div class="col-3" *ngIf="!generalInfoManagement.isEditing">
                        {{ generalInfo.name }}
                    </div>
                    <div class="col-3" *ngIf="generalInfoManagement.isEditing">
                        <stInput
                            type="text"
                            innerId="user-surname"
                            [(ngModel)]="generalInfoTmp.name"
                            name="name"
                            [isDisabled]="generalInfoManagement.isSaving"
                            required
                        >
                        </stInput>
                        <i class="invalid-feedback" *ngIf="!generalInfoTmp.name">
                            Ce champ est requis.
                        </i>
                    </div>
                </div>

                <div
                    class="row mb-3"
                    [ngClass]="{'d-flex align-items-center': !generalInfoManagement.isEditing}"
                >
                    <label
                        for="user-mail"
                        class="col-3 control-label"
                        [ngClass]="{'py-0': !generalInfoManagement.isEditing}"
                    >
                        Mail<sup>*</sup> :
                    </label>
                    <div class="col-3" *ngIf="!generalInfoManagement.isEditing">
                        {{ generalInfo.mail }}
                    </div>
                    <div class="col-3" *ngIf="generalInfoManagement.isEditing">
                        <stInput
                            type="mail"
                            innerId="user-mail"
                            [(ngModel)]="generalInfoTmp.mail"
                            name="mail"
                            [isDisabled]="generalInfoManagement.isSaving"
                            required
                        >
                        </stInput>
                        <i class="invalid-feedback" *ngIf="!generalInfoTmp.mail">
                            Ce champ est requis.
                        </i>
                    </div>
                </div>

                <div
                    class="row mb-3"
                    [ngClass]="{'d-flex align-items-center': !generalInfoManagement.isEditing}"
                >
                    <label
                        for="user-login"
                        class="col-3 control-label"
                        [ngClass]="{'py-0': !generalInfoManagement.isEditing}"
                    >
                        Identifiant de connexion<sup>*</sup> :
                    </label>
                    <div class="col-3" *ngIf="!generalInfoManagement.isEditing">
                        {{ generalInfo.login }}
                    </div>
                    <div class="col-3" *ngIf="generalInfoManagement.isEditing">
                        <stInput
                            type="text"
                            innerId="user-login"
                            [(ngModel)]="generalInfoTmp.login"
                            name="login"
                            [isDisabled]="generalInfoManagement.isSaving"
                            required
                        >
                        </stInput>
                        <i class="invalid-feedback" *ngIf="!generalInfoTmp.login">
                            Ce champ est requis.
                        </i>
                    </div>
                </div>
            </form>
            <div class="d-flex align-items-center mt-5" *ngIf="generalInfoManagement.isEditing">
                <stButton
                    btnLabel="Annuler"
                    btnClass="btn-st-default me-3"
                    (click)="cancelGeneralInfo()"
                    [isDisabled]="generalInfoManagement.isSaving"
                >
                </stButton>
                <stButton
                    btnLabel="Enregistrer les modifications"
                    btnClass="btn-st-primary ms-3"
                    (click)="validateGeneralInfo()"
                    [isDisabled]="_nomFG?._nom.error?.required || generalInfoManagement.isSaving || !isGeneralInfoFormCompleted()"
                >
                </stButton>
                <span
                    class="fa fa-2x fa-spinner fa-spin text-st-active ms-5"
                    *ngIf="generalInfoManagement.isSaving"
                >
                </span>
            </div>
        </div>
    </div>

    <hr class="separator" />

    <div id="role-group" class="container-fluid">
        <h4 class="d-inline-block mt-0">
            <b>Rôle et groupe</b>
        </h4>
        <span
            class="cursor-pointer ms-5"
            *ngIf="!roleGroupManagement.isEditing"
            (click)="roleGroupManagement.isEditing = true"
            [ngbTooltip]="tooltipContent"
        >
            <i class="fa fa-pencil"></i>
        </span>

        <div class="container-fluid">
            <form novalidate>
                <div class="row mb-3 d-flex align-items-center">
                    <label class="col-3 control-label py-0"> Rôle : </label>
                    <div class="col-3" *ngIf="!roleGroupManagement.isEditing">
                        {{ selectedRole.label }}
                    </div>
                    <div class="col-3" *ngIf="roleGroupManagement.isEditing">
                        <div class="w-100">
                            <stSelect
                                [(ngModel)]="selectedRole"
                                (change)="selectRole()"
                                [data]="roles"
                                valueField="fullObject"
                                labelField="label"
                                type="primary"
                                name="label"
                            >
                            </stSelect>
                        </div>
                    </div>
                </div>

                <div
                    class="row mb-3 d-flex align-items-center"
                    *ngIf="isUserSuperAdmin && selectedRole.id !== 1"
                >
                    <label class="col-3 control-label py-0"> Groupe : </label>
                    <div class="col-3" *ngIf="!roleGroupManagement.isEditing">
                        {{ selectedGroup.group?.nom }}
                    </div>
                    <div class="col-3" *ngIf="roleGroupManagement.isEditing">
                        <div class="w-100">
                            <stSelect
                                [(ngModel)]="selectedGroup"
                                (change)="selectGroup()"
                                [data]="groups"
                                labelField="group.nom"
                                valueField="fullObject"
                                type="primary"
                                name="group"
                            >
                            </stSelect>
                        </div>
                    </div>
                </div>
            </form>
            <div class="d-flex align-items-center mt-5" *ngIf="roleGroupManagement.isEditing">
                <stButton
                    btnLabel="Annuler"
                    btnClass="btn-st-default me-3"
                    (click)="cancelRoleGroup()"
                    [isDisabled]="roleGroupManagement.isSaving"
                >
                </stButton>
                <stButton
                    btnLabel="Enregistrer les modifications"
                    btnClass="btn-st-primary ms-3"
                    [isDisabled]="roleGroupManagement.isSaving"
                    (click)="validateRoleGroup()"
                >
                </stButton>
                <span
                    class="fa fa-2x fa-spinner fa-spin text-st-active ms-5"
                    *ngIf="roleGroupManagement.isSaving"
                >
                </span>
            </div>
        </div>
    </div>

    <hr class="separator" *ngIf="selectedRole.id === 3" />

    <div id="territory-rights" class="container-fluid" *ngIf="selectedRole.id === 3">
        <h4 class="d-inline-block mt-0">
            <b>Droits sur les territoires</b>
        </h4>
        <span
            class="cursor-pointer ms-5"
            *ngIf="!territoryManagement.isEditing"
            (click)="territoryManagement.isEditing = true"
            [ngbTooltip]="tooltipContent"
        >
            <i class="fa fa-pencil"></i>
        </span>

        <div class="container-fluid">
            <div id="geographical-boundaries">
                <h5>
                    <b>Périmètre géographique</b>
                </h5>

                <form novalidate>
                    <div
                        class="row mb-3"
                        [ngClass]="{'d-flex align-items-center': !territoryManagement.isEditing}"
                    >
                        <label class="col-3 control-label py-0"> Echelle de territoire : </label>
                        <div class="col-3" *ngIf="!territoryManagement.isEditing">
                            {{ selectedTerritoryScale.libelle_short }}
                        </div>
                        <div class="col-3" *ngIf="territoryManagement.isEditing">
                            <stSelect
                                [(ngModel)]="selectedTerritoryScale"
                                (change)="selectTerritoryScale()"
                                [data]="territoryScales"
                                order-by="ordre"
                                labelField="libelle_short"
                                valueField="fullObject"
                                type="primary"
                                name="territory"
                            >
                            </stSelect>
                        </div>
                    </div>
                    <div
                        class="row mb-3"
                        [ngClass]="{'d-flex align-items-center': !territoryManagement.isEditing}"
                    >
                        <label
                            for="territory"
                            class="col-3 control-label"
                            [ngClass]="{'py-0': !territoryManagement.isEditing}"
                        >
                            Territoire<sup>*</sup> :
                        </label>
                        <div class="col-3" *ngIf="!territoryManagement.isEditing">
                            {{ territoryLabels }}
                        </div>
                        <div class="col-3" *ngIf="territoryManagement.isEditing">
                            <stInputSearch
                                class="dropdown btn-block"
                                innerId="territory"
                                innerClass="border-07"
                                placeholder="Saisir le nom ou code INSEE"
                                label="labelId"
                                [options]="territories"
                                (onSelect)="addTerritory($event)"
                                [isDisabled]="!selectedTerritoryScale.id"
                            >
                            </stInputSearch>
                            <i class="invalid-feedback" *ngIf="!selectedTerritories.length">
                                Au moins un territoire est requis.
                            </i>
                        </div>
                    </div>
                    <div class="selected-territory" *ngIf="territoryManagement.isEditing">
                        <div
                            class="btn-group my-3 me-3"
                            *ngFor="let territory of selectedTerritories; let idx = index"
                        >
                            <stButton
                                btnLabel="{{ territory.label }}"
                                btnClass="btn-st-default d-flex align-items-center"
                                iconRight="fa fa-times ps-2"
                                [isDisabled]="territoryManagement.isSaving"
                                (click)="removeTerritory(idx)"
                            >
                            </stButton>
                        </div>
                    </div>
                </form>
            </div>

            <div id="granularity">
                <h5>
                    <b>Découpage géographique</b>
                </h5>

                <form novalidate>
                    <div class="row mb-3 d-flex align-items-center">
                        <label class="col-3 control-label py-0"> Granularité : </label>
                        <div class="col-3" *ngIf="!territoryManagement.isEditing">
                            {{ selectedGranularity.libelle_short }}
                        </div>
                        <div class="col-3" *ngIf="territoryManagement.isEditing">
                            <stSelect
                                [(ngModel)]="selectedGranularity"
                                [data]="granularities"
                                order-by="ordre"
                                labelField="libelle_short"
                                valueField="fullObject"
                                type="primary"
                                name="granularity"
                            >
                            </stSelect>
                        </div>
                    </div>
                </form>
            </div>

            <div class="d-flex align-items-center mt-5" *ngIf="territoryManagement.isEditing">
                <stButton
                    btnLabel="Annuler"
                    btnClass="btn-st-default me-3"
                    (click)="cancelTerritory()"
                >
                </stButton>
                <stButton
                    btnLabel="Enregistrer les modifications"
                    btnClass="btn-st-primary ms-3"
                    [isDisabled]="territoryManagement.isSaving || !selectedTerritories.length"
                    (click)="validateTerritory()"
                >
                </stButton>
                <span
                    class="fa fa-2x fa-spinner fa-spin text-st-active ms-5"
                    *ngIf="territoryManagement.isSaving"
                >
                </span>
            </div>
        </div>
    </div>

    <hr class="separator" *ngIf="selectedRole.id === 3" />

    <div id="indicator-rights" class="container-fluid" *ngIf="selectedRole.id === 3">
        <h4 class="d-inline-block mt-0">
            <b>Droits sur les indicateurs</b>
        </h4>
        <span
            class="cursor-pointer ms-5"
            *ngIf="!indicatorsManagement.isEditing"
            (click)="indicatorsManagement.isEditing = true"
            [ngbTooltip]="tooltipContent"
        >
            <i class="fa fa-pencil"></i>
        </span>

        <div class="container-fluid">
            <div id="indicator-list">
                <div
                    class="container-fluid mb-3"
                    *ngFor="let theme of indicatorsByTheme | orderBy: 'libelle'"
                >
                    <span class="cursor-pointer" (click)="theme.open = !theme.open">
                        <i class="fa fa-caret-down iw-4" *ngIf="theme.open"></i>
                        <i class="fa fa-caret-right iw-4" *ngIf="!theme.open"></i>
                        <span class="text-st-secondary fs-6">
                            <b>{{ theme.libelle }}</b>
                        </span>
                    </span>

                    <span
                        class="mx-4"
                        [ngClass]="{'cursor-pointer': indicatorsManagement.isEditing}"
                        (click)="selectAllIndicatorFromTheme(theme)"
                    >
                        <span *ngIf="theme.full == 2" class="fa fa-star"></span>
                        <span *ngIf="theme.full == 1" class="fa fa-star-half-o"></span>
                        <span *ngIf="theme.full == 0" class="fa fa-star-o"></span>
                    </span>

                    <ng-container *ngFor="let ss_theme of theme.ss_theme | orderBy: 'ordre'">
                        <div class="container-fluid mb-2" *ngIf="theme.open">
                            <span class="cursor-pointer" (click)="ss_theme.open = !ss_theme.open">
                                <i class="fa fa-caret-down iw-4" *ngIf="ss_theme.open"></i>
                                <i class="fa fa-caret-right iw-4" *ngIf="!ss_theme.open"></i>
                                <span>
                                    <b>{{ ss_theme.libelle }}</b>
                                </span>
                            </span>

                            <span
                                class="mx-4"
                                [ngClass]="{'cursor-pointer': indicatorsManagement.isEditing}"
                                (click)="selectAllIndicatorFromSubTheme(ss_theme)"
                            >
                                <span *ngIf="ss_theme.full == 2" class="fa fa-star"></span>
                                <span *ngIf="ss_theme.full == 1" class="fa fa-star-half-o"></span>
                                <span *ngIf="ss_theme.full == 0" class="fa fa-star-o"></span>
                            </span>

                            <div class="container-fluid px-5" *ngIf="ss_theme.open">
                                <div
                                    class=""
                                    *ngFor="let indicator of ss_theme.indicators | sortBy: 'ordre'"
                                    (click)="changeActiveIndicator(indicator)"
                                >
                                    <stCheckbox
                                        innerId="indicator-{{ indicator.id_indicateur }}"
                                        [(ngModel)]="indicator.active"
                                        [isDisabled]="!indicatorsManagement.isEditing"
                                    >
                                        {{ indicator.libelle_indic_short }}
                                        <span *ngIf="indicator.unit && indicator.unit !== '%'">
                                            - {{ indicator.unit }}
                                        </span>
                                    </stCheckbox>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="d-flex align-items-center mt-5" *ngIf="indicatorsManagement.isEditing">
                <stButton
                    btnLabel="Annuler"
                    btnClass="btn-st-default me-3"
                    (click)="cancelIndicators()"
                    [isDisabled]="indicatorsManagement.isSaving"
                >
                </stButton>
                <stButton
                    btnLabel="Enregistrer les modifications"
                    btnClass="btn-st-primary ms-3"
                    (click)="validateIndicators()"
                    [isDisabled]="indicatorsManagement.isSaving"
                >
                </stButton>
                <span
                    class="fa fa-2x fa-spinner fa-spin text-st-active ms-5"
                    *ngIf="indicatorsManagement.isSaving"
                >
                </span>
            </div>
        </div>
    </div>

    <hr class="separator" />

    <div class="container-fluid">
        <h4 class="mt-0">
            <b>Retourner à la page de gestion des utilisateurs</b>
        </h4>
        <!-- <a href="/administration/groups/manage/" class="btn">Retour</a> -->
        <stButton btnLabel="Retour" btnClass="btn-st-default" (click)="redirectToAdminUsers()">
        </stButton>
    </div>
</div>
